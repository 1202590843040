import { React, useState, useEffect, useContext } from "react";
import { Header, Footer } from "../components";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin } from "../actions/loginAction";
import { setLogin } from "../actions/loginAction";
import axios from "../axios";
import { useNavigate, NavLink } from "react-router-dom";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import { TranslationContext } from "../context";
const Signup = () => {
  const { content } = useContext(TranslationContext);
  const navigation = useNavigate();
  const [formErrors, setFormErrors] = useState({});

  const [register, setRegister] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    country: "",
    passport: "",
    password: "",
    con_password: "",
  });

  const errors = {};
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.login);
  const getCountry = useSelector((state) => state.getCountry);
  const [countryData, setCountryData] = useState([]);



  useEffect(() => {
    setCountryData(getCountry);
  }, [getCountry]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegister({ ...register, [name]: value });
  };

  const validation = () => {
    if (register.firstname.length === 0) {
      errors.firstname = content.enter_first_name;
    }
    if (register.lastname.length === 0) {
      errors.lastname = content.enter_last_name;
    }
    if (!validator.isNumeric(register.phone)) {
      errors.phone = content.phone_must_be_numeric;
    } else if (register.phone.length === 0) {
      errors.phone = content.enter_phone_no;
    } else if (register.phone.length < 10) {
      errors.phone = content.phone_no_digit_must_be;
    }
    if (!validator.isEmail(register.email) || register.email.length === 0) {
      errors.email = content.enter_valid_email_id;
    }
    if (register.country === "") {
      errors.country = content.select_country;
    }
    if (register.passport === "") {
      errors.passport = content.enter_passport_Id;
    }
    // if (!validator.isPassportNumber(register.passport,'IN') ) {
    //   errors.passport = "Enter valid Passport id";
    // }

    if (register.password.length === 0) {
      errors.password = content.enter_Password;
    } else if (register.password.length < 6) {
      errors.password = content.password_6_chr;
    }
    if (register.con_password.length === 0) {
      errors.con_password = content.enter_confirm_password;
    }
    if (register.password !== register.con_password) {
      errors.con_password = content.confirm_password_does_not_match;
    }
    setFormErrors(errors);
  };

  const resetForm = () => {
    setRegister({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      passport: "",
      country: "",
      password: "",
      con_password: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      const formData = {
        first_name: register.firstname,
        last_name: register.lastname,
        email: register.email,
        phone_number: register.phone,
        nationality_id: register.country,
        passport_id: register.passport,
        password: register.password,
        password_confirmation: register.con_password,
        language_code: localStorage.getItem("get_language"),
        device_id: "15255",
      };
      dispatch(asyncSignup(formData, resetForm));
    }
  };

  const asyncSignup = (formData, resetForm) => {
    return (dispatch) => {
      const url = "/signup";
      axios
        .post(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            resetForm();
            notifySuccess(data.message);
            navigation("/login");
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((err) => {
          alert(`${err.message}-${err.statusCode}`);
        });
    };
  };

  return (
    <section className="sign_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="sign_title">
              <h2>{content.signup}</h2>
              <p>{content.register_new_account}</p>
            </div>
          </div>
        </div>
        <form action="" className="my_profile_form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.first_name}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.firstname ? "is-invalid" : "")
                  }
                  id="firstname"
                  placeholder={content.enter_first_name}
                  value={register.firstname}
                  name="firstname"
                  onChange={handleChange}
                />
                {formErrors.firstname && (
                  <div className="invalid-feedback">{formErrors.firstname}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.last_name}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.lastname ? "is-invalid" : "")
                  }
                  value={register.lastname}
                  id="lastname"
                  placeholder={content.enter_last_name}
                  name="lastname"
                  onChange={handleChange}
                />
                {formErrors.lastname && (
                  <div className="invalid-feedback">{formErrors.lastname}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.email}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.email ? "is-invalid" : "")
                  }
                  id="email"
                  value={register.email}
                  placeholder={content.email}
                  name="email"
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <div className="invalid-feedback">{formErrors.email}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.phone_number}</p>
                <input
                  type="number"
                  className={
                    `form-control input_field   ` +
                    (formErrors.phone ? "is-invalid" : "")
                  }
                  id="phone"
                  value={register.phone}
                  placeholder={content.enter_phone_number}
                  name="phone"
                  onChange={handleChange}
                />
                {formErrors.phone && (
                  <div className="invalid-feedback">{formErrors.phone}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.passport_Id_number}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.passport ? "is-invalid" : "")
                  }
                  id="passport"
                  value={register.passport}
                  placeholder={content.enter_passport_Id_number}
                  name="passport"
                  onChange={handleChange}
                />
                {formErrors.passport && (
                  <div className="invalid-feedback">{formErrors.passport}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.country}</p>
                <select
                  name="country"
                  id="country"
                  className={
                    `form-control input_field   ` +
                    (formErrors.country ? "is-invalid" : "")
                  }
                  onChange={handleChange}
                >
                  <option value="">{content.select_country}</option>
                  {countryData.length > 0 &&
                    countryData.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>

                {formErrors.country && (
                  <div className="invalid-feedback">{formErrors.country}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.password}</p>
                <input
                  type="password"
                  className={
                    `form-control input_field   ` +
                    (formErrors.password ? "is-invalid" : "")
                  }
                  id="password"
                  value={register.password}
                  placeholder={content.enter_Password}
                  name="password"
                  onChange={handleChange}
                />
                {formErrors.password && (
                  <div className="invalid-feedback">{formErrors.password}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input">
                <p>{content.confirm_password}</p>
                <input
                  type="password"
                  className={
                    `form-control input_field   ` +
                    (formErrors.con_password ? "is-invalid" : "")
                  }
                  id="con_password"
                  placeholder={content.enter_confirm_password}
                  name="con_password"
                  value={register.con_password}
                  onChange={handleChange}
                />
                {formErrors.con_password && (
                  <div className="invalid-feedback">
                    {formErrors.con_password}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="">
                <div className="sign_form_btn">
                  <button type="submit" className="form_register_btn">
                    {content.register}
                  </button>
                </div>
                <div className="or_txt">
                  <p>
                    <span className="or_line">{content.or}</span>
                  </p>
                </div>
                <div className="already_account">
                  <p>
                   {content.already_have_an_account}
                    <span>
                      <NavLink to="/login" className="login_link">
                        {content.signin}
                      </NavLink>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Signup;
