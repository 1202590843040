import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  images,
  notifyDanger,
  notifySuccess,
  sweetSuccess,
} from "../../actions/customFn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/fontawesome-free-solid";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ModalTab from "./ModalTab";
import axios from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { MyPaymentForm } from "../Payement/payement";
import { asyncGetTotal } from "../../actions/bookingTotal";
import { asyncGetPayment } from "../../actions/orderAction";
import moment from "moment";
import Loading from "../Language/Loading";
import Script from "react-load-script";
import validator from "validator";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
// Paypal
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { setLogout } from "../../actions/loginAction";
// import { currency_code, currency_sign, omise_public_key } from "../../config";
import { TranslationContext } from "../../context";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

let Omise;
const BookingModal = (props) => {
  const { content, settings } = useContext(TranslationContext);
  const inputRef = useRef(null);
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { detail, BookingcloseModal, setTotalAmount, setuseEffectrender } =
    props;
  const [ispayLoading, setIsPayLoading] = useState(false);
  const [_error, setErrors] = useState({});
  const [cardValidation, setCardValidation] = useState({});
  const [bookingDetail, setBookingDetail] = useState([]);
  const [promoCodeCheck, setPromocodecheck] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [promocode_id, setPromocodeId] = useState("");
  const [OmiseCardValue, setOmiseCard] = useState();
  const [checkPaymentMethod, setPaymentMethod] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [qrCodemsg, setQrCodeMsg] = useState("");
  const [qrCodeLoader, setQrCodeLoader] = useState(false);
  const [paypalBtnStatus, setPaypalbtnStatus] = useState(false);
  const [registration_email, setRegistrationEmail] = useState("");
  const [registration_email_check, setRegistrationEmailCheck] = useState(false);
  const [currency_code, setCurrencyCode] = useState("");
  const [paypal_id, setPayPalClintId] = useState("");
  const [currency_symbol, setCurrenySymbol] = useState("");
  const MySwal = withReactContent(Swal);
  const [checkout, setCheckout] = useState({
    device_id: "dfdfd",
    language_code: localStorage.getItem("get_language"),
    customer_id: localStorage.getItem("lcruserId"),
    password: localStorage.getItem("lcrpassword"),
    promotioncode_id: promocode,
    shop_id: detail.shop_id,
    quantity: detail.quantity,
    special_quantity: detail.special_quantity,
    drop_date: detail.drop_date,
    pickup_date: detail.pick_date,
    insurance_id: detail.insurance_check,
    payment_method: "Omise",
    cart_name: "",
    cart_number: "",
    cart_security_code: "",
    cart_expiration_month: "",
    cart_expiration_year: "",
    currency: currency_code,
  });
  const islogin = useSelector((state) => state.login);
  const customerprofile = useSelector((state) => state.customerprofile);

  const payPalBtn = {
    layout: "horizontal",
    tagline: "false",
  };

  useEffect(() => {
    if (islogin !== false) {
      if (customerprofile.length > 0) {
        setCurrencyCode(customerprofile[0].currency_code);
        setCurrenySymbol(customerprofile[0].currency_symbol);
      } else {
        setCurrencyCode(settings.default_currency);
        setCurrenySymbol(settings.default_currency_symbol);
      }
    }
    if (detail.order_id !== "" && typeof detail.order_id !== "undefined") {
      setCurrencyCode(detail.currency_code);
      setCurrenySymbol(detail.currency_symbol);
    }

    if (islogin === false) {
      setCurrencyCode(settings.default_currency);
      setCurrenySymbol(settings.default_currency_symbol);
    }
  }, [
    customerprofile,
    detail.currency_code,
    detail.currency_symbol,
    detail.order_id,
    islogin,
    settings.default_currency,
    settings.default_currency_symbol,
  ]);

  useEffect(() => {
    setCheckout({
      device_id: "dfdfd",
      language_code: localStorage.getItem("get_language"),
      customer_id: localStorage.getItem("lcruserId"),
      password: localStorage.getItem("lcrpassword"),
      promotioncode_id: promocode,
      shop_id: detail.shop_id,
      quantity: detail.quantity,
      special_quantity: detail.special_quantity,
      drop_date: detail.drop_date,
      pickup_date: detail.pick_date,
      insurance_id: detail.insurance_check,
      payment_method: "Omise",
      cart_name: "",
      cart_number: "",
      cart_security_code: "",
      cart_expiration_month: "",
      cart_expiration_year: "",
      currency: currency_code,
    });
  }, [currency_code, detail, promocode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckout({ ...checkout, [name]: value });
  };

  const getTotal = useSelector((state) => state.getTotal);
  const all_tittle = {
    image_one: images["visa1.png"],
    image_two: images["paypal.png"],
    image_three: images["send1.png"],
  };
  const errors = {};
  const card_errors = {};
  const paypal_errors = {};
  const dispatch = useDispatch();

  const validation = () => {
    if (promocode === "") {
      errors.promocode = "Enter Promocode";
    }
    setErrors(errors);
  };
  const paypal_validation = () => {
    if (islogin === false) {
      if (registration_email === "") {
        setRegistrationEmailCheck(true);
        paypal_errors.registration_email = content.enter_email;
      }
      if (
        !validator.isEmail(registration_email) ||
        registration_email.length === 0
      ) {
        paypal_errors.registration_email = content.enter_valid_email_id;
      }
    }
    setCardValidation(paypal_errors);
  };

  const card_validation = () => {
    if (checkout.cart_name === "") {
      card_errors.cart_name = content.enter_card_holder_name;
    }

    if (checkout.cart_number === "") {
      card_errors.cart_number = content.enter_card_number;
    } else if (!validator.isNumeric(checkout.cart_number)) {
      card_errors.cart_number = content.phone_must_be_numeric;
    }
    if (checkout.cart_security_code === "") {
      card_errors.cart_security_code = content.enter_security_code;
    }
    if (checkout.cart_security_code === "") {
      card_errors.cart_security_code = content.enter_security_code;
    }
    if (!validator.isNumeric(checkout.cart_security_code)) {
      card_errors.cart_security_code = content.phone_must_be_numeric;
    }
    if (checkout.cart_expiration_year === "") {
      card_errors.cart_expiration_year = content.enter_exp_year;
    }

    if (islogin === false) {
      setRegistrationEmailCheck(true);
      if (registration_email === "") {
        card_errors.registration_email = content.enter_email;
      }
      if (
        !validator.isEmail(registration_email) ||
        registration_email.length === 0
      ) {
        card_errors.registration_email = content.enter_valid_email_id;
      }
    }
    setCardValidation(card_errors);
  };

  useEffect(() => {
    setBookingDetail(getTotal);
  }, [getTotal, bookingDetail]);

  //Check Promocode Start
  const handePromocode = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      const data = {
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        promotion_code: promocode,
        shop_id: detail.shop_id,
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/applypromotioncode";
      axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            var promocode_id = data.promotion_id;
            setPromocodeId(data.promotion_id);
            notifySuccess(data.message);
            setPromocodecheck(false);
            const setData = "";
            const sendData = {
              device_id: "dfdfd",
              language_code: localStorage.getItem("get_language"),
              customer_id: localStorage.getItem("lcruserId"),
              password: localStorage.getItem("lcrpassword"),
              shop_id: detail.shop_id,
              quantity: detail.quantity,
              special_quantity: detail.special_quantity,
              drop_date: detail.drop_date,
              pickup_date: detail.pick_date,
              insurance_id: detail.insurance_check,
              promotioncode_id: promocode_id,
            };
            dispatch(
              asyncGetTotal(
                sendData,
                setData,
                "",
                props.BookingModal,
                setTotalAmount
              )
            );
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  async function checkCustomer(email) {
    var status = true;
    if (islogin !== true) {
      const url = "/check_customer_exist";
      const data = {
        language_code: localStorage.getItem("get_language"),
        email: email,
      };
      const response = await axios.post(url, data);
      if (response.data.status === true) {
        return (status = await response.data.status);
      } else {
        status = false;
        notifyDanger(response.data.message);
        navigation("/login", { state: pathname });
      }
    }
    return status;
  }

  const handleConfirOrder = (e) => {
    e.preventDefault();
    card_validation();
    const result = checkCustomer(registration_email).then((res) => {
      if (res === true) {
        if (Object.keys(card_errors).length === 0) {
          setIsPayLoading(true);
          const formData = checkout;
          if (islogin === false) {
            formData.email = registration_email;
          }
          if (detail.order_id !== "") {
            formData.order_id = detail.order_id;
          }
          const url = "/confirmorder";
          axios
            .post(url, formData)
            .then((response) => {
              const { data } = response;
              if (data.status) {
                setIsPayLoading(false);
                navigation("/order-detail/" + data.data.id);
                sweetSuccess(data.message);
                localStorage.removeItem("specialquantity");
                localStorage.removeItem("quantity");
                localStorage.removeItem("dropDate");
                localStorage.removeItem("pickUpDate");
                closeModal();
                setuseEffectrender(true);
              } else {
                if (data.message === "401") {
                  dispatch(setLogout(navigation));
                  notifyDanger("Somthing went wrong...");
                } else {
                  setIsPayLoading(false);
                  notifyDanger(data.message);
                }
              }
            })
            .catch((err) => {
              if (err.response.status === "401") {
                dispatch(setLogout(navigation));
                notifyDanger("Somthing went wrong...");
              } else {
                alert(`${err.message}-${err.response.status}`);
              }
            });
        }
      }
    });
  };

  const paypalConfirmOrder = (data) => {
    data.device_id = "dfdfd";
    data.language_code = "en";
    if (islogin === true) {
      data.customer_id = localStorage.getItem("lcruserId");
      data.password = localStorage.getItem("lcrpassword");
    } else {
      data.email = registration_email;
    }
    // if (Object.keys(card_errors).length === 0) {
    setIsPayLoading(true);
    const url = "/confirmpaypal";
    axios
      .post(url, data)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setIsPayLoading(false);
          sweetSuccess(data.message);
          closeModal();
          localStorage.removeItem("specialquantity");
          localStorage.removeItem("quantity");
          localStorage.removeItem("dropDate");
          localStorage.removeItem("pickUpDate");
          navigation("/order-detail/" + data.data.id);
          setuseEffectrender(true);
        } else {
          if (data.message === "401") {
            dispatch(setLogout(navigation));
            notifyDanger("Somthing went wrong...");
          } else {
            setIsPayLoading(false);
            notifyDanger(data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === "401") {
          dispatch(setLogout(navigation));
          notifyDanger("Somthing went wrong...");
        } else {
          alert(`${err.message}-${err.response.status}`);
        }
      });
    // }
  };

  const paypalValidation = () => {
    if (islogin === false) {
      paypal_validation();
      if (Object.keys(paypal_errors).length === 0) {
        checkCustomer(registration_email).then((res) => {
          if (res === true) {
            setPaypalbtnStatus(false);
          }
        });
      } else {
        setPaypalbtnStatus(true);
      }
    }
  };

  const closeModal = () => {
    BookingcloseModal(false);
    setPromocodecheck(false);
    setPromocode("");
    setPromocodeId("");
  };
  //Payment Script End

  const exportAsPicture = (src) => {
    var data = document.getElementById(src);

    MySwal.fire({
      title: `<h2 class="swal2-my">${content.you_want_download_qr} ?</h2>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FBDA5C",
      cancelButtonColor: "#d33",
      confirmButtonText: content.yes_download_id,
    }).then((res) => {
      if (res.isConfirmed) {
        html2canvas(data, {
          allowTaint: true,
          useCORS: true,
        }).then((canvas) => {
          html2canvas(data, {
            allowTaint: true,
            useCORS: true,
          }).then((canvas) => {
            const dataURL = canvas.toDataURL();
            const link = document.createElement("a");
            link.download = "qrCode.png";
            link.href = dataURL;
            link.click();
          });
        });
      }
    });
  };

  const handleSelect = (key) => {
    setPaymentMethod(key);
    if (key === "scanbar") {
      setQrCodeLoader(true);
      let data = {
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        shop_id: detail.shop_id,
        currency: currency_code,
        amount: props.bookingTotalamount,
      };
      if (islogin === false) {
        if (registration_email === "") {
          card_errors.registration_email = "Enter Email";
        }
        if (
          !validator.isEmail(registration_email) ||
          registration_email.length === 0
        ) {
          card_errors.registration_email = "Enter valid Email ";
        }
        setCardValidation(card_errors);
        data.email = registration_email;
      }
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/omiseqrtoken";
      axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setQrCode(data.data.qr_code);
            setCustomerName(data.data.customer_name);
          } else {
            setQrCode("");
            setCustomerName("");
            setQrCodeMsg(data.message);
          }
          setQrCodeLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (islogin === false) {
      if (key === "paypal") {
        paypalValidation();
      }
    }
  };

  useEffect(() => {
    setPayPalClintId(settings.paypal_client_id);
  }, [settings.paypal_client_id]);

  return (
    <div className="modal_main">
      {ispayLoading === true ? (
        <div className="new_loader">
          <Loading />
        </div>
      ) : (
        ""
      )}
      <Modal
        className="booking_popup"
        show={props.BookingModal}
        onHide={closeModal}
      >
        <div className="payment_model" id="payment">
          <div className="modal-dialog  modal-lg">
            <div className="modal-content">
              <div className="modal-header payment_header">
                <a href={void 0}>
                  <h4 className="modal-title">
                    <span>
                      <img
                        src={images["pop_top.png"]}
                        style={{ width: "45px" }}
                        className="mr-1"
                      />
                    </span>
                    {content.checkout_payment}
                    <span className="pay_inner_chek">
                      {" "}
                      {content.choose_your_preferred_payment_method}
                    </span>
                  </h4>
                </a>
                <button
                  type="button"
                  onClick={closeModal}
                  className="close"
                  data-dismiss="modal"
                >
                  {/* &times; */}
                  <img
                    src={images["close_icon.png"]}
                    style={{ width: "40px" }}
                  />
                </button>
              </div>
              <div className="modal-body payment_body">
                <div className="pay_section">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="pay_conatin">
                        {islogin === false ? (
                          <div className="account_reg">
                            <h2>{content.returning_customer}</h2>
                            <p>
                              {content.enter_your_email_to}{" "}
                              <a
                                style={{ color: "#fbda5c", cursor: "pointer" }}
                                href={void 0}
                                onClick={() => {
                                  registration_email_check === true
                                    ? setRegistrationEmailCheck(false)
                                    : setRegistrationEmailCheck(true);
                                }}
                              >
                                {" "}
                                {content.create_account}{" "}
                              </a>{" "}
                              or{" "}
                              <NavLink to="/login">
                                {" "}
                                {content.login_here}{" "}
                              </NavLink>
                            </p>
                            {registration_email_check === true ? (
                              <div className="email_input">
                                <span className="email_icon">
                                  <i
                                    className="fa fa-envelope-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <input
                                  type="email"
                                  name="email"
                                  id="registration_email"
                                  value={registration_email}
                                  onChange={(e) => {
                                    setRegistrationEmail(e.target.value);
                                  }}
                                  onBlur={() => {
                                    paypalValidation();
                                  }}
                                  className="form-control emailInput"
                                  placeholder={content.enter_your_email}
                                />
                                {cardValidation.registration_email && (
                                  <div className="text-danger">
                                    {cardValidation.registration_email}
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="payment_detail new_payment_option">
                          <Tabs
                            onSelect={handleSelect}
                            defaultActiveKey="visa"
                            id="uncontrolled-tab-example"
                            className="nav  nav-pills rounded nav-fill"
                          >
                            <Tab
                              eventKey="visa"
                              title={
                                <React.Fragment>
                                  {content.credit_or_debit_card}{" "}
                                  <img
                                    src={images["visa_.png"]}
                                    className="ml-2"
                                  />
                                  <p className="text_title_btm">
                                    {" "}
                                    {
                                      content.please_use_credit_or_debit_to_make_seamless_checkout
                                    }
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <div className="card__payment">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="pay_card">
                                      <div className="card_header">
                                        <div
                                          id="credit-card"
                                          className="paymentForm tab-pane fade show active"
                                        >
                                          <form role="form">
                                            <div className="creditTab">
                                              <div className="formInput">
                                                <div className="form-group">
                                                  <label className="all_label">
                                                    {content.cardholder_name}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name="cart_name"
                                                    placeholder={
                                                      content.card_owner_name
                                                    }
                                                    className={
                                                      `form-control input_field   ` +
                                                      (cardValidation.cart_name
                                                        ? "is-invalid"
                                                        : "")
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  {cardValidation.cart_name && (
                                                    <div className="text-danger">
                                                      {cardValidation.cart_name}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="form-group">
                                                  <label className="all_label">
                                                    {content.card_number}
                                                  </label>
                                                  <input
                                                    type="number"
                                                    name="cart_number"
                                                    placeholder={
                                                      content.valid_card_number
                                                    }
                                                    className={
                                                      `form-control input_field   ` +
                                                      (cardValidation.cart_number
                                                        ? "is-invalid"
                                                        : "")
                                                    }
                                                    onChange={handleChange}
                                                  />

                                                  {cardValidation.cart_number && (
                                                    <div className="text-danger">
                                                      {
                                                        cardValidation.cart_number
                                                      }
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-8">
                                                  <div className="form-group">
                                                    {" "}
                                                    <label className="all_label">
                                                      {content.expiry_date}
                                                    </label>
                                                    <div className="input-group">
                                                      <input
                                                        type="number"
                                                        placeholder="MM"
                                                        name="cart_expiration_month"
                                                        className={
                                                          `form-control input_field   ` +
                                                          (cardValidation.cart_expiration_month
                                                            ? "is-invalid"
                                                            : "")
                                                        }
                                                        onChange={handleChange}
                                                      />{" "}
                                                      <input
                                                        type="number"
                                                        placeholder="YY"
                                                        name="cart_expiration_year"
                                                        className={
                                                          `form-control input_field   ` +
                                                          (cardValidation.cart_expiration_year
                                                            ? "is-invalid"
                                                            : "")
                                                        }
                                                        onChange={handleChange}
                                                      />{" "}
                                                    </div>
                                                    {cardValidation.cart_expiration_year && (
                                                      <div className="text-danger">
                                                        {
                                                          cardValidation.cart_expiration_year
                                                        }
                                                      </div>
                                                    )}
                                                    {cardValidation.cart_expiration_month && (
                                                      <div className="text-danger">
                                                        {
                                                          cardValidation.cart_expiration_month
                                                        }
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="col-sm-4">
                                                  <div className="form-group mb-4">
                                                    <label>
                                                      {content.cvv_cvc}
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className={
                                                        `form-control input_field   ` +
                                                        (cardValidation.cart_security_code
                                                          ? "is-invalid"
                                                          : "")
                                                      }
                                                      placeholder={
                                                        content.cvv_cvc
                                                      }
                                                      name="cart_security_code"
                                                      onChange={handleChange}
                                                    />
                                                    {cardValidation.cart_security_code && (
                                                      <div className="text-danger">
                                                        {
                                                          cardValidation.cart_security_code
                                                        }
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pay_agreeCondition">
                                                <div className="pay_now">
                                                  <button
                                                    name="submit_promocode"
                                                    className="submit_promocode"
                                                    onClick={handleConfirOrder}
                                                  >
                                                    {content.pay_now}{" "}
                                                    {currency_symbol}{" "}
                                                    {props.bookingTotalamount}{" "}
                                                  </button>
                                                </div>
                                                <p className="text_pop_btm">
                                                  <img
                                                    src={images["pop_btm.png"]}
                                                    style={{ width: "26px" }}
                                                    className="mr-1"
                                                  />
                                                  {
                                                    content.payment_transaction_encryption
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="paypal"
                              title={
                                <React.Fragment>
                                  {content.pay_with_paypal}{" "}
                                  <img
                                    src={images["paypal.png"]}
                                    className="ml-2"
                                  />
                                  <p className="text_title_btm">
                                    {" "}
                                    {
                                      content.please_use_your_paypal_to_complete_the_faster_checkout
                                    }
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <div className="tab-pane  pt-3 p-3">
                                <div className="table_total">
                                  <p>{content.total_amount}</p>
                                  <p>
                                    {currency_symbol} {props.bookingTotalamount}
                                  </p>
                                </div>
                                <div className="paypal_btn">
                                  {checkPaymentMethod === "paypal" ? (
                                    // islogin === true ? (
                                    <PayPalScriptProvider
                                      options={{
                                        "client-id": paypal_id,
                                        currency: currency_code
                                          ? currency_code.toUpperCase()
                                          : "",
                                      }}
                                    >
                                      <PayPalButtons
                                        title={content.pay_now}
                                        // ref={inputRef}
                                        disabled={paypalBtnStatus}
                                        className="submit_promocode"
                                        style={{ layout: "horizontal" }}
                                        createOrder={(data, actions) => {
                                          return actions.order.create({
                                            purchase_units: [
                                              {
                                                amount: {
                                                  value:
                                                    props.bookingTotalamount,
                                                },
                                              },
                                            ],
                                          });
                                        }}
                                        onApprove={(data, actions) => {
                                          return actions.order
                                            .capture()
                                            .then((details) => {
                                              if (
                                                details.status === "COMPLETED"
                                              ) {
                                                let data = {
                                                  payment_method: "paypal",
                                                  quantity: detail.quantity,
                                                  drop_date: detail.drop_date,
                                                  pickup_date: detail.pick_date,
                                                  shop_id: detail.shop_id,
                                                  insurance_id:
                                                    detail.insurance_check,
                                                  promotioncode_id: promocode,
                                                  paypal_transation: details,
                                                  currency: currency_code,
                                                  special_quantity:
                                                    detail.special_quantity,
                                                  intent: details.intent,
                                                  transaction_id: details.id,
                                                };
                                                if (detail.order_id != "") {
                                                  data.order_id =
                                                    detail.order_id;
                                                }
                                                paypalConfirmOrder(data);
                                              } else {
                                                notifyDanger(
                                                  content.payment_was_failed_please_try_again
                                                );
                                              }
                                            });
                                        }}
                                        onError={(err) => {
                                          notifyDanger(
                                            content.something_went_wrong
                                          );
                                        }}
                                      />
                                    </PayPalScriptProvider>
                                  ) : (
                                    ""
                                  )}
                                  <p className="text_pop_btm">
                                    <img
                                      src={images["pop_btm.png"]}
                                      style={{ width: "26px" }}
                                      className="mr-1"
                                    />
                                    {content.payment_transaction_encryption}
                                  </p>
                                </div>
                              </div>
                            </Tab>
                            {/* Paypal */}

                            <Tab
                              eventKey="scanbar"
                              title={
                                <React.Fragment>
                                  {content.thai_qr_promptpay}{" "}
                                  <img
                                    src={images["promp_pay.png"]}
                                    className="ml-2"
                                  />
                                  <p className="text_title_btm">
                                    {content.please_use_thai_qr_promptpay}
                                  </p>
                                </React.Fragment>
                              }
                            >
                              <div className="qr_scancode text-center">
                                {qrCodeLoader === true ? (
                                  <Loading />
                                ) : qrCode !== "" ? (
                                  <>
                                    {/* <img src={qrCode} alt=""  /> */}
                                    <div
                                      className="my_qr"
                                      id="qr_image"
                                      style={{ padding: "10px" }}
                                      dangerouslySetInnerHTML={{
                                        __html: qrCode,
                                      }}
                                    ></div>

                                    <div className="client_name">
                                      <p>{content.refrence_name} </p>
                                      <span>{customer_name}</span>
                                    </div>
                                    <p>
                                      {content.total_amount}: {currency_symbol}{" "}
                                      {props.bookingTotalamount}
                                    </p>
                                    <div className="btn_save_qr">
                                      <a
                                        href={void 0}
                                        onClick={() => {
                                          exportAsPicture("qr_image");
                                        }}
                                      >
                                        {" "}
                                        {content.save_qr}
                                      </a>
                                    </div>

                                    <div className="info_text">
                                      <p>
                                        {
                                          content.if_your_payment_successful_system
                                        }
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <div className="qr_not">
                                    <p className="text-danger">{qrCodemsg}</p>
                                  </div>
                                )}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default BookingModal;
