import { Route } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../assets/images", false));

//Get Routs
const GetRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.route) {
      return (
        <Route exact path={route.route} element={route.page} key={route.key} />
      );
    }

    return null;
  });

const notifySuccess = (notification) =>
  toast.success(notification, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyDanger = (notification) =>
  toast.error(notification, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const calcTime = (offset) => {
  const d = new Date();

  //Deal with dates in milliseconds for most accuracy
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const newDateWithOffset = new Date(utc + 3600000 * offset);

  //This will return the date with the locale format (string), or just return newDateWithOffset
  //and go from there.
  return newDateWithOffset.toLocaleString();
};

const sweetSuccess = (message) => {
    const MySwal = withReactContent(Swal)
    MySwal.fire({
        position: "center",
        icon: "success",
        text: message,
        iconColor: '#FECB00',
        showConfirmButton: false,
        timer: 1500,
    });
};
export { images, GetRoutes, notifySuccess, notifyDanger, calcTime ,sweetSuccess };
