import React from 'react'
import { BecomePartner, DownloadApp, HomeBanner, PricesSection, StorageNetwork, StoreBag } from '../components'

const HomeMain = () => {
    return (
        <>
            <HomeBanner />
            <StoreBag />
            <StorageNetwork />
            <PricesSection />
            <DownloadApp />
            <BecomePartner />
        </>
    )
}

export default HomeMain