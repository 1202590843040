import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import {
  asyncChangePassword,
} from "../../actions/myprofile";
import { TranslationContext } from "../../context";

const ChangePassword = () => {
  const { content } = useContext(TranslationContext);
  const [formErrors2, setFormErrors2] = useState({});
  const errors = {};
  const dispatch   = useDispatch();
  const navigation = useNavigate();

  const [passwordDetail, setPasswordDetail] = useState({
    confirm_password: "",
    new_password: "",
    current_password: "",
  });

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordDetail({ ...passwordDetail, [name]: value });
  };

  const validation = () => {
    if (passwordDetail.current_password.length === 0) {
      errors.current_password = content.enter_Password;
    }

    if (passwordDetail.new_password.length === 0) {
      errors.new_password = content.enter_new_Password;
    } else if (passwordDetail.new_password.length < 6) {
      errors.new_password = content.new_password_6_chr;
    }
    if (passwordDetail.new_password !== passwordDetail.confirm_password) {
      errors.confirm_password = content.confirm_password_not_match;
    }
    setFormErrors2(errors);
  };

  const resetForm = () => {
    setPasswordDetail({
      confirm_password: "",
      new_password: "",
      current_password: "",
    });
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        language_code: localStorage.getItem("get_language"),
        device_id: "15255",
        current_password: passwordDetail.current_password,
        new_password: passwordDetail.new_password,
        confirm_password: passwordDetail.confirm_password,
      };
      dispatch(asyncChangePassword(data, resetForm,navigation));
    }
  };

  return (
    
    <section className="account_section editprofile_Section">
      <div className="right_editProfile">
        <div className="white_box edit_whiteBox">
          <div className="editProfile_title">
            <h2>{content.change_password}</h2>
          </div>
        </div>
        <div className="white_box">
          <div className="userProfile_form change_password">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.current_password}</p>
                  <input
                    type="password"
                    className={
                      `form-control name_input  ` +
                      (formErrors2.current_password ? "is-invalid" : "")
                    }
                    name="current_password"
                    onChange={handleChange}
                    value={passwordDetail.current_password}
                    placeholder="Enter Current Password"
                  />
                  {formErrors2.current_password && (
                    <div className="invalid-feedback">
                      {formErrors2.current_password}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.new_password}</p>
                  <input
                    type="password"
                    name="new_password"
                    onChange={handleChange}
                    value={passwordDetail.new_password}
                    className={
                      `form-control name_input  ` +
                      (formErrors2.new_password ? "is-invalid" : "")
                    }
                    placeholder="Enter New Password"
                  />
                  {formErrors2.new_password && (
                    <div className="invalid-feedback">
                      {formErrors2.new_password}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.confirm_password}</p>
                  <input
                    type="password"
                    name="confirm_password"
                    onChange={handleChange}
                    value={passwordDetail.confirm_password}
                    className={
                      `form-control name_input  ` +
                      (formErrors2.confirm_password ? "is-invalid" : "")
                    }
                    placeholder="Enter Confirm Password"
                  />
                  {formErrors2.confirm_password && (
                    <div className="invalid-feedback">
                      {formErrors2.confirm_password}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border_bottom">
            <hr />
          </div>
          <div className="user_profileBtn">
            <div className="password_checkbox"></div>
            <div className="save_btn">
              <a
                href="javascript:void(0);"
                onClick={handleUpdateProfile}
                className="saveBtn"
              >
               {content.update_password}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
