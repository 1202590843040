import axios from "../axios";
import { notifyDanger } from "./customFn";
import { asyncGetLogout } from "./loginAction";

export const setBookingTotal = (data) => {
  return {
    type: "SET_TOTAl",
    payload: data,
  };
};

export const asyncGetTotal = (
  data,
  setData,
  setUserBookingDetail,
  setBookingModal,
  setTotal,
  setTotalLoader,
  setBookingTotalAmount
) => {
  return (dispatch) => {
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    const url = "/gettotal";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status === true) {
          dispatch(setBookingTotal(data.data));
          setTotal(data.data);
          setBookingTotalAmount(data.total_value);
          if (setData !== "") {
            setUserBookingDetail({
              drop_date       : setData.dropDate,
              pick_date       : setData.pickDate,
              quantity        : setData.quantity,
              special_quantity: setData.specialquantity,
              name            : setData.name,
              shop_id         : setData.shop_id,
              insurance_check : setData.guranteecheck,
              order_id        : setData.order_id !== "" ? setData.order_id          : "",
              currency_code   : setData.currency_code !== "" ? setData.currency_code: "",
              currency_symbol : setData.currency_symbol !== "" ? setData.currency_symbol: "",
            });
            setBookingModal(true);
          }
         
          // setIsLoading(false);
        } else {
          setTotal([]);
          notifyDanger(data.message);
        }
        setTimeout(() => {
          if(setTotalLoader!=''){
            setTotalLoader(false);
          }
        }, 2000);
      })
      .catch((err) => {
        if (err instanceof TypeError) {
          
        }else{
          if (err.response.status === "401") {
            dispatch(asyncGetLogout());
            notifyDanger("Somthing went wrong...");
          } else {
            alert(`${err.message}-${err.response.status}`);
          }
        }
      });
  };
};
