import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
  calcTime,
  images,
  notifyDanger,
  notifySuccess,
} from "../actions/customFn";
import BookingDetails from "../components/Modal/BookingDetails";
import axios from "../axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Language/Loading";
import Geocode from "react-geocode";
import { currency_code, map_key } from "../config";
import { useDispatch } from "react-redux";
import { setLogout } from "../actions/loginAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/fontawesome-free-solid";
import "flatpickr/dist/themes/confetti.css";
import Flatpickr from "react-flatpickr";
import { asyncGetTotal } from "../actions/bookingTotal";
import moment from "moment";
import { BookingModal, ModalTab } from "../components";
import "add-to-calendar-button";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import ReviewModal from "../components/Modal/ReviewModal";
import ReactStars from "react-rating-stars-component";
import { TranslationContext } from "../context";
const ReviewList = () => {
  const { content } = useContext(TranslationContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [review_list, setReviewList] = useState([]);
  const ratingStar = {
    size: 30,
    count: 5,
    isHalf: false,
    color: "black",
    activeColor: "#fbda5c",
    edit: false,
  };

  //Api Start
  useEffect(() => {
    setIsLoading(true);
    const loadShopDetail = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/review";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
        shop_id: id,
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setReviewList(data.data);
            setIsLoading(false);
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadShopDetail();
  }, [id]);
  return (
    <div>
      {isLoading === true ? (
        <Loading />
      ) : (
        <section className="yourbooking_section">
          <div className="container">
            {review_list.length > 0 ? (
              <>
                <div className="store_title">
                  <h2>{content.review_list}</h2>
                </div>
                <div className="row">
                  {review_list.map((value, index) => {
                    return (
                      <div className="review_txt" key={index}>
                        <div className="reviewTxt justify-content-between mb-3">
                          <div class="profile">
                            <div class="profile-img">
                              <img src={value.customer_image} />
                            </div>
                            <div class="name-user">
                              <strong>{value.customer_name}</strong>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <ReactStars
                                {...ratingStar}
                                value={parseInt(value.rating)}
                              />
                            </li>
                            <li>
                              <span>
                                {/* ({value.rating} {content.stars}) */}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div class="client-comment">
                          <p>{value.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-md-12 text-center">
                  <h3>{content.no_record_found}</h3>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};
export default ReviewList;
