import React, { useContext, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "../../axios";
import { useDispatch } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { TranslationContext } from "../../context";
const ReviewModal = (props) => {
  const { content } = useContext(TranslationContext);
  const [add_review, setReview] = useState({
    rating: "",
    description: "",
  });
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReview({ ...add_review, [name]: value });
  };
  const errors = {};
  const validation = () => {
    if (add_review.rating.length === 0) {
      errors.rating = content.select_rating;
    }
    if (add_review.description.length === 0) {
      errors.description = content.enter_description;
    }
    setFormErrors(errors);
  };
  const resetForm = () => {
    setReview({
        rating: "",
        description: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
        if(localStorage.getItem("lcruserId")){
            let data               = add_review;
                data.customer_id   = localStorage.getItem("lcruserId");
                data.password      = localStorage.getItem("lcrpassword");
                data.shop_id       = props.orderDetails.shop_id;
                data.order_id      = props.orderDetails.id;
                data.language_code = localStorage.getItem("get_language");
                data.device_id     = "dfdfd";
                dispatch(asyncAddReview(data, resetForm));

        }
    }
  };
  const asyncAddReview = (formData, resetForm) => {
    return (dispatch) => {
      const url = "/reviewadd";
      axios
        .post(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            resetForm();
            notifySuccess(data.message);
            props.addReviewModal()
            props.setReviewButton(false)
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((err) => {
          alert(`${err.message}-${err.statusCode}`);
        });
    };
  };

  const ratingStar = {
    size: 40,
    count: 5,
    isHalf: false,
    value: 0,
    color: "black",
    activeColor: "#fbda5c",
    onChange: (newValue) => {
      setReview({ ...add_review, ['rating']: newValue });
    }
  };
  return (
    <div className="modal_main">
      <Modal
        className="bookingdetails_popup"
        show={props.reviewModal}
        onHide={props.addReviewModal}
      >
        <div className="booking_model" id="Booking">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header booking_header">
                <h4 className="modal-title">{content.add_review}</h4>
                <button
                  type="button"
                  onClick={props.addReviewModal}
                  className="close modal_close_btn"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{content.rating}</Form.Label>
                    <ReactStars {...ratingStar}  />
                    
                    {formErrors.rating && (
                      <div className="invalid-feedback">
                        {formErrors.rating}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group
                    className={` mb-3 `}
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>{content.description}</Form.Label>
                    <textarea
                      className={
                        ` form-control input_field  ` + (formErrors.description ? "is-invalid" : "")
                      }
                      placejolder={content.enter_description}
                      rows={3}
                      name="description"
                      onChange={handleChange}
                    />
                    {formErrors.description && (
                      <div className="invalid-feedback">
                        {formErrors.description}
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="modal_btn"
                  variant="primary"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {content.save_changes}
                </button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewModal;
