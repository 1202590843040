import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMobile,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
  faCalendarAlt,
  faCalendar,
} from "@fortawesome/fontawesome-free-solid";
import { faSearch } from "@fortawesome/fontawesome-free-solid";
import Drawer from "react-modern-drawer";
import axios from "../../axios";
import { NavLink, useNavigate } from "react-router-dom";
import { images, notifyDanger } from "../../actions/customFn";
import ReactLoading from "react-loading";
import Loading from "../Language/Loading";
import { map_key } from "../../config";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
} from "@react-google-maps/api";
import "flatpickr/dist/themes/confetti.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import SearchBox from "./SearchBox";
import MyLocationMarker from "./MyLocationMarker";
import Geocode from "react-geocode";
import { TranslationContext } from "../../context";

const MapLocation = (props) => {
  const { content, settings } = useContext(TranslationContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSpecificStatus, setSpecificStatus] = useState(false);
  const [shopsArr, setShops] = useState([]);
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");
  const [dropDownFilter, setDropDownFilter] = useState("");
  const [specificDate, setSpecificDate] = useState(new Date());
  const [active, setActive] = useState("");
  const [lat_lng, setLatLng] = useState({
    address: props.address != "" ? props.address.replaceAll("_", " ") : "",
    lat:
      props.latitude != "" && !isNaN(parseFloat(props.latitude))
        ? parseFloat(props.latitude)
        : 15.87,
    lng:
      props.longitude != "" && !isNaN(parseFloat(props.longitude))
        ? parseFloat(props.longitude)
        : 100.9925,
  });
  const [map_key, setMapKey] = useState("");
  const [filterLocation, setFilterLocation] = useState({
    latitude: "",
    longitude: "",
  });
  const [currentLocation, setCurrentLocation] = useState({
    latitude: "",
    longitude: "",
  });

  const [mapPosition, setMapPosition] = useState({
    center: {
      lat:
        props.latitude != "" && !isNaN(parseFloat(props.latitude))
          ? parseFloat(props.latitude)
          : 13.7563,
      lng:
        props.longitude != "" && !isNaN(parseFloat(props.longitude))
          ? parseFloat(props.longitude)
          : 100.5018,
    },
    zoom: 10,
  });

  const [isOpen, setIsOpen] = useState(true);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setMapKey(settings.google_map_key);
    if (typeof settings.google_map_key != "undefined") {
      Geocode.setApiKey(settings.google_map_key);
    }
  }, [settings.google_map_key]);

  //Api Starts
  useEffect(() => {
    setIsLoading(true);
    const shopsList = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/shops";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
        customer_id: localStorage.getItem("lcruserId"),
      };
      if (props.address != "" && props.address != null) {
        data.address = props.address;
      }
      if (filterLocation.latitude != "") {
        data.latitude = filterLocation.latitude;
      } else {
        if (props.latitude != "" && props.latitude != null) {
          data.latitude = props.latitude;
        }
      }
      if (filterLocation.latitude != "") {
        data.longitude = filterLocation.longitude;
      } else {
        if (props.longitude != "" && props.longitude != null) {
          data.longitude = props.longitude;
        }
      }

      if (dropDownFilter === "specific_date") {
        data.date = moment(specificDate).format("yyyy-MM-DD hh:mm");
      }
      data.filter = dropDownFilter;
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            if (data.data.length > 0) {
              setShops(data.data);
            } else {
              setShops([]);
            }
          } else {
            setShops([]);
            notifyDanger(data.message);
          }
          setIsLoading(false);
          setMapPosition({
            center: {
              lat: parseFloat(props.latitude),
              lng: parseFloat(props.longitude),
            },
            zoom: 14,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    // if (languageReducer.length !== 0) {
    shopsList();
    // }
  }, [
    dropDownFilter,
    filterLocation,
    props.address,
    props.latitude,
    props.longitude,
    specificDate,
  ]);
  //Api Starts

  const getDirection = (lat, lng, index) => {
    setActive(index);
    setMapPosition({
      center: {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
      zoom: 16,
    });
  };

  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  const validation = () => {
    if (lat_lng.address === "") {
      errors.address = content.enter_address;
    }
    if (lat_lng.lan === "" && isNaN(parseFloat(props.lan))) {
      errors.lan = content.enter_valid_address;
    }
    if (lat_lng.lat === "" && isNaN(parseFloat(props.lat))) {
      errors.lat = content.enter_valid_address;
    }
    setFormErrors(errors);
  };
  const navigate = useNavigate();

  const FilterLocation = () => {
    validation();
    if (Object.keys(errors).length === 0) {
      navigate(
        `/visit-lockr/${parseFloat(lat_lng.lat)}/${parseFloat(lat_lng.lng)}/${
          lat_lng.address
        }`
      );
    }
  };

  //Locations
  const inputRef = useRef();
  const handlePlaceChanged = (place) => {
    if (place) {
      setLatLng({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  //Slecte Filter locatio
  const handleSelect = (e) => {
    if (e.target.value === "specific_date") {
      setSpecificStatus(true);
    } else {
      setSpecificStatus(false);
    }
    setDropDownFilter(e.target.value);
  };

  const redirectOnBookingMarker = (id) => {
    navigate(`/booking/${id}`);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            var address = response.results[0].formatted_address;
            setCurrentLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error(error);
          }
        );
      },
      function (errorObj) {
        // alert(errorObj.code + ": " + errorObj.message);
      }
    );
  }, []);

  return (
    <>
      <section className="search_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12">
              <div className="search_container">
                <h6>{content.search_location}</h6>
                <div className="search_bar">
                  {map_key !== "" ? (
                    // <SearchBox
                    //   map_key={map_key}
                    //   handlePlaceChanged={handlePlaceChanged}
                    //   address={
                    //     props.address !== ""
                    //       ? props.address.replaceAll("_", " ")
                    //       : ""
                    //   }
                    // />
                    <Autocomplete
                      libraries={["places"]}
                      apiKey={map_key !== "" ? map_key : ""}
                      className={`form-control input-text search_input`}
                      defaultValue={
                        props.address !== ""
                          ? props.address.replaceAll("_", " ")
                          : ""
                      }
                      onPlaceSelected={(place) => {
                        handlePlaceChanged(place);
                      }}
                      options={{
                        // fields: [
                        //   "address_components",
                        //   "geometry",
                        //   "icon",
                        //   "name",
                        // ],
                        types: ["establishment"],
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {/* <Autocomplete
                    libraries={["places"]}
                    // apiKey={map_key}
                    className={
                      `form-control input-text search_input   ` +
                      (formErrors.address ? "is-invalid" : "")
                    }
                    defaultValue={props.address !== "" ? props.address : ""}
                    onPlaceSelected={(place) => {
                      handlePlaceChanged(place);
                    }}
                    options={{
                      // fields: [
                      //   "address_components",
                      //   "geometry",
                      //   "icon",
                      //   "name",
                      // ],
                      types: ["establishment"],
                    }}
                  /> */}
                  {formErrors.address && (
                    <div className="text-danger">{formErrors.address}</div>
                  )}
                  <div className="input-group-append">
                    <button
                      className="btn search_icon"
                      onClick={FilterLocation}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-7 col-sm-12 col-12">
              <div className="dis_main">
                <div className="category_selection">
                  <h6>{content.filter_location}</h6>
                  <div className="category_list" onChange={handleSelect}>
                    <select>
                      <option>{content.select_filter}</option>
                      <option value="open_today">{content.open_today}</option>
                      <option value="open_24">{content.open_24_7}</option>
                      <option value="specific_date">
                        {content.specific_date}
                      </option>
                    </select>
                  </div>
                </div>
                {isSpecificStatus === true ? (
                  <div className="category_selection ml-3">
                    <div class="drop_title">
                      <h6>{content.specific_date}</h6>
                    </div>
                    <div className="datepicker date input-group date_picker">
                      <div className="input-group-append iconInput">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>
                      </div>
                      <Flatpickr
                        className={`form-control input_loc`}
                        placeholder={content.specific_date}
                        options={{
                          minDate: "today",
                        }}
                        value={specificDate}
                        onChange={(selected) => {
                          setSpecificDate(selected[0]);
                        }}
                        name="pickUp"
                        id="pickUp"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <button className="drawer_button_desktop" onClick={toggleDrawer}>
                {isOpen ? "List Close" : " Show Map"}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="map_all_loaction">
        <div style={{ height: "100vh", width: "100%" }}>
          {/* <LoadScript id="search-loader2" googleMapsApiKey={map_key}> */}
          <GoogleMapReact
            bootstrapURLKeys={{
              key:
                typeof settings.google_map_key != "undefined"
                  ? settings.google_map_key
                  : "",
            }}
            center={mapPosition.center}
            zoom={mapPosition.zoom}
          >
            {shopsArr.map((val, key) => {
              return (
                <Marker
                  key={key}
                  uKey={key}
                  active={active}
                  onClick={() => {
                    redirectOnBookingMarker(val.id);
                  }}
                  title={val.name}
                  lat={val.address_latitude}
                  lng={val.address_longitude}
                />
              );
            })}
            <MyLocationMarker
              active={true}
              title={"Your Current Location"}
              lat={currentLocation.latitude}
              lng={currentLocation.longitude}
            />
          </GoogleMapReact>
          {/* </LoadScript> */}
        </div>
        <div className="map_loaction_side">
          <section className="visit_lockr">
            <div className="drawer_main_map">
              {/* <button className='drawer_open' onClick={toggleDrawer}>
                <FontAwesomeIcon icon={faArrowRight} /></button> */}
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="left"
                className="side_bar_main"
              >
                <div>
                  <button className="drawer_close" onClick={toggleDrawer}>
                    {" "}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                  <div className="booking_req">
                    <h4>
                      {content.number_of_shops}: {shopsArr.length}
                    </h4>
                  </div>
                  <div className="border_bottom">
                    <hr />
                  </div>
                  <div className="choose_place">
                    <div className="near_places">
                      {isLoading === true ? (
                        <Loading />
                      ) : shopsArr.length > 0 ? (
                        shopsArr.map((val, index) => {
                          return (
                            <>
                              <div
                                className="places_box"
                                key={index}
                                onMouseOver={() => {
                                  getDirection(
                                    val.address_latitude,
                                    val.address_longitude,
                                    index
                                  );
                                }}
                              >
                                <div className="row">
                                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 orderChange">
                                    <div className="place_info">
                                      <h6>{val.name}</h6>
                                      <div className="shop_detail">
                                        <ul>
                                          <li>
                                            <span className="icon_loc">
                                              <FontAwesomeIcon
                                                icon={faMapMarkerAlt}
                                              />
                                            </span>
                                            {val.address}
                                          </li>
                                          <li>
                                            <span className="icon_loc">
                                              <FontAwesomeIcon
                                                icon={faMobile}
                                              />
                                            </span>
                                            <a href="javascript:void(0)">
                                              {val.phone_number}
                                            </a>
                                          </li>
                                          <li>
                                            <span className="icon_loc">
                                              <FontAwesomeIcon
                                                icon={faEnvelope}
                                              />
                                            </span>
                                            <a href={"mailto:" + val.email}>
                                              {val.email}
                                            </a>
                                          </li>
                                          {val.opening_closing_times.length >
                                          0 ? (
                                            <li>
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faClock}
                                                />
                                              </span>
                                              {val.open_hours}
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                          <li>
                                            <span>
                                              <FontAwesomeIcon
                                                icon={faCalendarAlt}
                                              />
                                            </span>
                                            {val.opening_days}
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="moreInfo_txt">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            getDirection(
                                              val.address_latitude,
                                              val.address_longitude
                                            );
                                          }}
                                          className="more_infoTxt"
                                        >
                                          {content.directions}
                                        </a>
                                        <NavLink
                                          to={`/booking/${val.id}`}
                                          className="bookNow_btn"
                                        >
                                          {content.book_now}
                                        </NavLink>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div className="place_detail">
                                      <img src={val.image} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="border_bottom">
                                <hr />
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div className="places_box">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 orderChange">
                              <div className="place_info">
                                <h6>{content.no_record_found}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default MapLocation;
