import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
  calcTime,
  images,
  notifyDanger,
  notifySuccess,
} from "../actions/customFn";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import BookingDetails from "../components/Modal/BookingDetails";
import axios from "../axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Language/Loading";
import Geocode from "react-geocode";
// import { currency_code, map_key } from "../config";
import { useDispatch } from "react-redux";
import { setLogout } from "../actions/loginAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/fontawesome-free-solid";
import "flatpickr/dist/themes/confetti.css";
import Flatpickr from "react-flatpickr";
import { asyncGetTotal } from "../actions/bookingTotal";
import moment from "moment";
import { BookingModal, ModalTab } from "../components";
import "add-to-calendar-button";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import ReviewModal from "../components/Modal/ReviewModal";
import { TranslationContext } from "../context";
const OrderDetail = () => {
  const MySwal = withReactContent(Swal);
  const { content, settings } = useContext(TranslationContext);
  // Geocode.setApiKey(map_key);
  const timezoneOffset = new Date().getTimezoneOffset();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, SetOrderDetail] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [set_quantity, setset_quantity] = useState(0);
  const [set_specialquantity, setset_specialquantity] = useState(0);
  const [specialquantity, setSpecialQuantity] = useState(0);
  const navigation = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [reviewButton, setReviewButton] = useState(false);
  const [currency_code, setCurrencyCode] = useState("");

  const [orderCheck, setOrderCheck] = useState({
    add_bag: false,
    add_date: false,
  });
  //Review
  const [reviewModal, setReviewModal] = useState(false);
  //Review

  //Bokking
  const [bookingTotalamount, setBookingTotalAmount] = useState(0);
  const [dropOff_date, setdropOff_date] = useState("");
  const [pickUp_date, setpickUp_date] = useState("");
  const [BookModal, setBookingModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [bookingDetail, setBookingDetail] = useState([]);
  const [useEffectrender, setuseEffectrender] = useState(false);
  const [guranteecheck, setGuranteecheck] = useState("");
  const [userbookingDetail, setUserBookingDetail] = useState({
    drop_date: "",
    pick_date: "",
    quantity: "",
    special_quantity: "",
    name: "",
    shop_id: "",
    insurance_check: "",
    order_id: "",
    currency_code: "",
    currency_symbol: "",
  });

  //Bokking
  const errors = {};
  const dispatch = useDispatch();
  const showBookingModal = () => {
    setBookingModal(true);
  };
  const closeBookingModal = () => {
    setBookingModal(false);
  };
  useEffect(() => {
    if (id) {
      const data = {
        id: id,
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
      };
      const url = "/orderdetail/";
      axios
        .post(url, data)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            SetOrderDetail(data.data);
            setdropOff_date(data.data.drop_date);
            setset_quantity(
              data.data.quantity !== null && data.data.quantity !== ""
                ? data.data.quantity
                : 0
            );
            setset_specialquantity(
              data.data.special_quantity != null &&
                data.data.special_quantity !== ""
                ? data.data.special_quantity
                : 0
            );
            setpickUp_date(data.data.pickup_date);
            setQuantity(
              data.data.quantity !== null && data.data.quantity !== ""
                ? data.data.quantity
                : 0
            );
            setSpecialQuantity(
              data.data.special_quantity !== null &&
                data.data.special_quantity !== ""
                ? data.data.special_quantity
                : 0
            );
            setIsLoading(false);
            setReviewButton(data.data.review_status);
          } else {
            if (data.message === "401") {
              navigation("/login");
            }
          }
        })
        .catch((err) => {
          if (err.response.status === "401") {
            notifyDanger("Somthing went wrong...");
            navigation("/login");
          } else {
            alert(`${err.message}-${err.response.status}`);
          }
        });
    }
  }, [id, navigation, useEffectrender]);

  useEffect(() => {
    setCurrencyCode(settings.default_currency);
  }, [settings.default_currency]);

  //Send Info EMail Start
  const sendInfomail = () => {
    if (localStorage.getItem("lcruserId")) {
      const data = {
        id: id,
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
      };
      const url = "/ordersendemail/";
      axios
        .post(url, data)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            notifySuccess(data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === "401") {
            notifyDanger(content.something_went_wrong);
            dispatch(setLogout(navigation));
          } else {
            alert(`${err.message}-${err.response.status}`);
          }
        });
    } else {
      notifyDanger("Login First");
    }
  };
  //Send Info EMail End

  const getDirection = (shopLat, shopLng, shopAddress, shopName) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      var url = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${shopLat},${shopLng}`;
      MySwal.fire({
        title: `<h2 class="swal2-my ">Direction to ${shopName} ?</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FBDA5C",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        // title: `Direction to ${shopName}`,
        // showCancelButton: true,
        // confirmButtonColor: "#FBDA5C",
        // confirmButtonText: "OK",
      }).then((res) => {
        if (res.isConfirmed) {
          var windRefrence = window.open();
          windRefrence.location = url;
        }
      });
    });
  };

  const handleRoomCount = (name, status) => {
    if (status === "increment") {
      if (name === "quantity") {
        setQuantity(quantity + 1);
      } else {
        setSpecialQuantity(specialquantity + 1);
      }
    } else {
      if (name === "quantity") {
        if (quantity > 0 && quantity > set_quantity) {
          setQuantity(quantity - 1);
        }
      } else {
        if (specialquantity > 0 && specialquantity > set_specialquantity) {
          setSpecialQuantity(specialquantity - 1);
        }
      }
    }
  };

  const validation = () => {
    if (orderCheck.add_date === true) {
      if (dropOff_date === "") {
        errors.dropOff_date = content.enter_drop_off_date;
      }
      if (pickUp_date === "") {
        errors.pickUp_date = content.enter_pick_up_date;
      }
      if (Date.parse(pickUp_date) === Date.parse(dropOff_date)) {
        errors.pickUp_date = content.drop_off_pick_up_are_same;
      }
    }
    if (orderCheck.add_bag === true) {
      if (quantity === 0 && specialquantity === 0) {
        errors.quantity = content.enter_quantity_atleast_1;
      }
    }
    setFormErrors(errors);
  };

  const handleAddbagCheck = (status, status2) => {
    status.replace('"', "");
    if (status2 === "remove") {
      setOrderCheck({ ...orderCheck, [status]: false });
    } else {
      setOrderCheck({ ...orderCheck, [status]: !orderCheck.status });
    }
  };

  //----submit GetTotal Start
  const handleGetTotal = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      var dropDate = "";
      var pickDate = "";
      dropDate = orderDetails.drop_date;
      pickDate = orderDetails.pickup_date;
      if (pickUp_date !== "" && dropOff_date !== "") {
        if (dropOff_date !== dropDate) {
          dropDate = moment(dropOff_date).format("yyyy-MM-DD h:mm A");
        }
        if (pickDate !== pickUp_date) {
          pickDate = moment(pickUp_date).format("yyyy-MM-DD h:mm A");
        }
      }

      // if (localStorage.getItem("lcruserId")) {
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
        shop_id: orderDetails.shop_id,
        quantity: quantity,
        special_quantity: specialquantity,
        drop_date: dropDate,
        pickup_date: pickDate,
        insurance_id: guranteecheck,
        currency: currency_code,
        order_id: id,
      };
      const setData = {
        dropDate: dropDate,
        pickDate: pickDate,
        quantity: quantity,
        specialquantity: specialquantity,
        name: orderDetails.shop_name,
        shop_id: orderDetails.shop_id,
        guranteecheck: guranteecheck,
        order_id: id,
        currency_code: orderDetails.currency_code,
        currency_symbol: orderDetails.currency_symbol,
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/validdateorder";
      axios
        .post(url, data, options)
        .then((response) => {
          if (response.data.status === true) {
            dispatch(
              asyncGetTotal(
                data,
                setData,
                setUserBookingDetail,
                setBookingModal,
                setTotalAmount,
                "",
                setBookingTotalAmount
              )
            );
          } else {
            notifyDanger(response.data.message);
          }
        })
        .catch((err) => {});
    }
  };
  //----submit Form End

  // ------ Add Review Modale
  const addReviewModal = () => {
    if (reviewModal === true) {
      setReviewModal(false);
    } else {
      setReviewModal(true);
    }
  };
  // ------ Add Review Modale End
  return (
    <div>
      {isLoading === true ? (
        <Loading />
      ) : (
        <section className="yourbooking_section">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="booking_your">
                  <div className="checkIn_table">
                    <table className="table ">
                      <tbody>
                        <tr>
                          <th>{content.check_in} </th>
                          <th>{content.check_out}</th>
                          <th>{content.bags}</th>
                        </tr>
                        <tr>
                          <td>{orderDetails.drop_date}</td>
                          <td>{orderDetails.pickup_date}</td>
                          <td>
                            {parseInt(orderDetails.quantity) +
                              parseInt(
                                orderDetails.special_quantity == null
                                  ? 0
                                  : orderDetails.special_quantity
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="add_btn">
                    {orderCheck.add_bag === true ? (
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          handleAddbagCheck("add_bag", "remove");
                        }}
                        className="add_items text-danger"
                      >
                        <span>
                          <img src={images["add_bag.png"]} alt="" />
                        </span>
                        {content.remove_bag}
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          handleAddbagCheck("add_bag");
                        }}
                        className="add_items"
                      >
                        <span>
                          <img src={images["add_bag.png"]} alt="" />
                        </span>
                        {content.add_bag}
                      </a>
                    )}

                    {orderCheck.add_date === true ? (
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          handleAddbagCheck("add_date", "remove");
                        }}
                        className="add_items text-danger"
                      >
                        <span>
                          <img src={images["add_calendar.png"]} alt="" />
                        </span>
                        {content.remove_day}
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          handleAddbagCheck("add_date");
                        }}
                        className="add_items"
                      >
                        <span>
                          <img src={images["add_calendar.png"]} alt="" />
                        </span>
                        {content.add_day}
                      </a>
                    )}
                  </div>
                  {orderCheck.add_date === true ? (
                    <>
                      <div className="picker_contain mt-2">
                        <div className="drop_title">
                          <h5>{content.drop_off}</h5>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="datepicker date input-group date_picker">
                              <div className="input-group-append iconInput">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faCalendar} />
                                </span>
                              </div>
                              <Flatpickr
                                //   className={
                                //     `form-control input_field   ` +
                                //     (formErrors.dropOff_date ? "is-invalid" : "")
                                //   }
                                options={{
                                  minDate:
                                    orderDetails.drop_date !== ""
                                      ? orderDetails.drop_date
                                      : "",
                                }}
                                disabled={true}
                                data-enable-time
                                value={dropOff_date}
                                name="dropOff_date"
                                onChange={(selected) => {
                                  setdropOff_date(selected[0]);
                                }}
                              />
                            </div>
                            {formErrors.dropOff_date && (
                              <div className="text-danger">
                                {formErrors.dropOff_date}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="picker_contain mt-2">
                        <div className="drop_title">
                          <h5>{content.pick_up}</h5>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="datepicker date input-group date_picker">
                              <div className="input-group-append iconInput">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faCalendar} />
                                </span>
                              </div>
                              <Flatpickr
                                //   className={
                                //     `form-control input_field   ` +
                                //     (formErrors.pickUp_date ? "is-invalid" : "")
                                //   }

                                options={{
                                  minDate: pickUp_date,
                                  disable: [
                                    {
                                      function(date) {
                                        return date.getDate() < pickUp_date;
                                      },
                                    },
                                  ],
                                }}
                                data-enable-time
                                value={pickUp_date}
                                onChange={(selected) => {
                                  setpickUp_date(selected[0]);
                                }}
                                name="pickUp_date"
                              />
                            </div>
                            {formErrors.pickUp_date && (
                              <div className="text-danger">
                                {formErrors.pickUp_date}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {orderCheck.add_bag === true ? (
                    <div className="items_selection mt-3">
                      <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-4 col-sm-4 col-12">
                          <div className="no_bags">
                            <h6>{content.regular_items}</h6>
                            <div className="quantity_field">
                              <div className="btn_selector d-flex justify-content-between">
                                <button
                                  onClick={() =>
                                    handleRoomCount("quantity", "increment")
                                  }
                                >
                                  {" "}
                                  +{" "}
                                </button>
                                <p>{quantity}</p>
                                <button
                                  onClick={() =>
                                    handleRoomCount("quantity", "decrement")
                                  }
                                >
                                  {" "}
                                  -{" "}
                                </button>
                              </div>
                              {formErrors.quantity && (
                                <div className="text-danger">
                                  {formErrors.quantity}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-4 col-sm-4 col-12">
                          <div className="no_bags">
                            <h6>{content.special_items}</h6>
                            <div className="quantity_field">
                              <div className="btn_selector d-flex justify-content-between">
                                <button
                                  onClick={() =>
                                    handleRoomCount(
                                      "special_quantity",
                                      "increment"
                                    )
                                  }
                                >
                                  {" "}
                                  +{" "}
                                </button>
                                <p>{specialquantity}</p>
                                <button
                                  onClick={() =>
                                    handleRoomCount(
                                      "special_quantity",
                                      "decrement"
                                    )
                                  }
                                >
                                  {" "}
                                  -{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                          <div className="item_category">
                            <h6> Types Of Items</h6>
                            <div className="item_list">
                              <select>
                                <option>SpeedBoat</option>
                                <option>Plane</option>
                                <option>Bike</option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {orderCheck.add_bag === true ||
                  orderCheck.add_date === true ? (
                    <div className="book_now">
                      <button
                        className="book_btn"
                        onClick={handleGetTotal}
                        data-toggle="modal"
                        data-target="#payment"
                      >
                        {content.book_now}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="bookingAddress_detail">
                    <h6>{orderDetails.shop_address}</h6>
                    {/* <h5>Lorem ipsum dolor sit amet - S</h5>
                    <p>
                      Lorem Ipsum has been the industry's standard dummy text -
                      S
                    </p> */}
                    <div className="get_direction">
                      <button
                        href="#"
                        onClick={() => {
                          getDirection(
                            orderDetails.shop_address_latitude,
                            orderDetails.shop_address_longitude,
                            orderDetails.shop_address,
                            orderDetails.shop_name
                          );
                        }}
                        className="direction_btn"
                        data-toggle="modal"
                        data-target="#Booking"
                      >
                        {content.get_direction}
                      </button>
                    </div>
                    <div className="add_data">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          sendInfomail();
                        }}
                        className="send_mail"
                      >
                        <span className="send_icon">
                          <img src={images["send.png"]} alt="" />
                        </span>
                        {content.send_info_by_email}
                      </a>
                      <add-to-calendar-button
                        styleLight="--btn-background: #FBDA5C;"
                        styleDark="--btn-background: #000;"
                        name={orderDetails.shop_name}
                        description={orderDetails.calender_description}
                        className="add_calend"
                        startDate={
                          orderDetails.drop_date !== ""
                            ? moment(orderDetails.drop_date).format(
                                "yyyy-MM-DD"
                              )
                            : ""
                        }
                        startTime={
                          orderDetails.drop_date !== ""
                            ? moment(orderDetails.drop_date).format("hh:mm")
                            : ""
                        }
                        endTime={
                          orderDetails.pickup_date !== ""
                            ? moment(orderDetails.pickup_date).format("hh:mm")
                            : ""
                        }
                        endDate={
                          orderDetails.pickup_date !== ""
                            ? moment(orderDetails.pickup_date).format(
                                "yyyy-MM-DD"
                              )
                            : ""
                        }
                        location={orderDetails.shop_address}
                        options="'Apple','Google','iCal','Outlook.com','Yahoo'"
                      ></add-to-calendar-button>
                    </div>

                    <div className="table_review_ord">
                      <table>
                        <tr>
                          <td>{content.payment_info}:</td>
                        </tr>
                        <tr>
                          <td>{content.storage_items}:</td>
                          <td>
                            {orderDetails.quantity
                              ? orderDetails.quantity +
                                " " +
                                content.regular_items
                              : ""}{" "}
                            {orderDetails.special_quantity
                              ? orderDetails.special_quantity +
                                " " +
                                content.special_items
                              : ""}{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>{content.duration}:</td>
                          <td>{orderDetails.duration}</td>
                        </tr>

                        {orderDetails.insurance_total != null &&
                        orderDetails.insurance_total !== 0 &&
                        orderDetails.insurance_total !== "0.00" ? (
                          <tr>
                            <td>{content.insurance}:</td>
                            <td>
                              {orderDetails.insurance_total}
                              {"  "}
                              {orderDetails.currency_code
                                ? orderDetails.currency_code.toUpperCase()
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {orderDetails.promotion_code !== "" ? (
                          <>
                            <td>
                              {content.promotion_discount} (
                              {orderDetails.promotion_code}){" "}
                            </td>{" "}
                            <td>{orderDetails.promotion_total}</td>
                          </>
                        ) : (
                          ""
                        )}

                        {orderDetails.tax_total ? (
                          <tr>
                            <td>
                              {orderDetails.tax_title != "" ||
                              orderDetails.tax_title != null
                                ? orderDetails.tax_title
                                : "Tax"}{" "}
                            </td>{" "}
                            <td>
                              {orderDetails.tax_total}{" "}
                              {orderDetails.currency_code
                                ? orderDetails.currency_code.toUpperCase()
                                : ""}
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}

                        <tr>
                          <td>{content.total_amount}:</td>
                          <td>
                            {orderDetails.total}{" "}
                            {orderDetails.currency_code
                              ? orderDetails.currency_code.toUpperCase()
                              : ""}
                          </td>
                        </tr>
                      </table>
                    </div>

                    {reviewButton === true ? (
                      <div className="useful_info">
                        {/* <p>Other usefull information:</p> */}
                        <ul>
                          <li>
                            <a
                              href={void 0}
                              onClick={() => {
                                addReviewModal();
                              }}
                            >
                              {content.add_review}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="luggage_dropoff">
                  <div className="your_luggage">
                    <span>
                      <img src={images["luggage.png"]} alt="" />
                    </span>
                    <p>{content.today_you_finally_drop_off_your_luggage}</p>
                  </div>
                  <div className="qr_code">
                    <p className="check_qr">
                      {content.check_in_with_qr_code_or_use_the_recoverycode}
                    </p>
                    <div className="scan_bar">
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="scanner">
                            <img
                              src={orderDetails.qrcode_image}
                              alt=""
                              style={{ width: "128px" }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="recovery_code">
                            <div className="recovery_contain">
                              <h6>{content.customer_name}</h6>
                              <p>
                                {orderDetails.first_name}{" "}
                                {orderDetails.last_name}
                              </p>
                            </div>
                            <div className="orderId">
                              <h6>{content.order_id}</h6>
                              <p>{orderDetails.order_id}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="need_assistance">
                  <h6>{content.do_you_need_assistance}</h6>
                  <p>
                    {
                      content.contact_our_customer_support_team_via_the_live_chat
                    }{" "}
                    <a
                      href={`mailto:${content.orderDetail_mail}`}
                      className="mail_id"
                    >
                      {content.orderDetail_mail}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="border_bottom">
              <hr />
            </div>
          </div>
          <ReviewModal
            addReviewModal={addReviewModal}
            reviewModal={reviewModal}
            orderDetails={orderDetails}
            setReviewButton={setReviewButton}
          />
          <BookingModal
            BookingModal={BookModal}
            detail={userbookingDetail}
            bookingDetail={bookingDetail}
            BookingcloseModal={closeBookingModal}
            setTotalAmount={setTotalAmount}
            totalAmount={totalAmount}
            setuseEffectrender={setuseEffectrender}
            bookingTotalamount={bookingTotalamount}
          />
        </section>
      )}
    </div>
  );
};
export default OrderDetail;
