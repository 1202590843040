import React, { useContext } from "react";
import { images } from "../actions/customFn";
import { TranslationContext } from "../context";

const UnderContruction = () => {
  const { settings } = useContext(TranslationContext);
  return (
    <div className="container">
      <div className="under_cun">
        <p className="under_cun2">Lockr will be back soon!</p>
        <p className="under_cun3">
          <span className="under_cun4">
            We are upgrading our system for you to feel more seamless
            experience.
          </span>
          <br />
          <span className="under_cun5">Please check back soon.</span>
        </p>
        
        <a href={settings.facebook}>
          <img src={images["under_facebook.jpg"]} className="facebook" alt="" />
        </a>
        <a href={settings.instagram}>
          <img
            src={images["under_instagram.jpg"]}
            className="instagram"
            alt=""
          />
        </a>
        <a href={`mailto:${settings.footer_email}`}>
          <img src={images["under_gmail.jpg"]} className="gmail" alt="" />
        </a>
        <a href={settings.line_id}>
          <img src={images["under_underline.jpg"]} className="line" alt="" />
        </a>
        <a href={`tel:${settings.footer_phone_number}`}>
          <img src={images["under_phone.jpg"]} className="phone" alt="" />
        </a>
        <iframe
          src="https://lottie.host/?file=8184b0b2-0129-461b-8b1e-e77f715afd66/VFXgtnYdG8.json"
          style={{ "margin-top": "5%", height: "300px", width: "500px" }}
        ></iframe>
      </div>
    </div>
  );
};

export default UnderContruction;
