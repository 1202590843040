import React from "react";
const NotFound = () => {
  return (
    <div className="faq_main_all">
      <div className="container">
        <div className="Faq_heading">
          <h2> 404 Not Found </h2>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
