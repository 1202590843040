import { React, useState, useEffect, useContext } from "react";
import { Header, Footer } from "../components";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin } from "../actions/loginAction";
import { setLogin } from "../actions/loginAction";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { TranslationContext } from "../context";
const Login = () => {
  const { content } = useContext(TranslationContext);
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [loginStatus, setloginStatus] = useState(false);
  const { state } = useLocation();

  const errors = {};
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.login);
  useEffect(() => {
    if (localStorage.getItem("lcrpassword") || loginStatus === true) {
      dispatch(setLogin());
      if(state !== null && state !== '/signup' && state !== '/forget_password' && state !== '/reset_password' ){
        navigation(state);
      }else{
        navigation("/my-profile");
      }
    } else {
      setloginStatus(false);
    }
  }, [dispatch, isLoggedIn, loginStatus]);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const validation = () => {
    if (!validator.isEmail(email)) {
      errors.email = content.enter_valid_email_id;
    } else {
    }
    if (password.length === 0) {
      errors.password = content.enter_valid_password;
    }
    setFormErrors(errors);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  ///Login Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      const formData = {
        language_code: localStorage.getItem("get_language"),
        device_id: "fffffff",
        email: email,
        password: password,
      };
      dispatch(asyncLogin(formData, resetForm, setloginStatus));
    }
  };

  return (
    <section className="sign_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="sign_title">
              <h2>{content.login}</h2>
              {/* <p>Register new account</p> */}
            </div>
          </div>
        </div>
        <form action="" className="my_profile_form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.email}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.email ? "is-invalid" : "")
                  }
                  id="username"
                  placeholder={content.enter_email}
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <div className="invalid-feedback">{formErrors.email}</div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.password}</p>
                <input
                  type="password"
                  className={
                    `form-control input_field ` +
                    (formErrors.password ? " is-invalid" : "")
                  }
                  placeholder={content.enter_Password}
                  id="password-input"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {formErrors.password && (
                  <div className="invalid-feedback">{formErrors.password}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="">
                <div className="sign_form_btn">
                  <button type="submit" onClick={handleSubmit} className="form_register_btn">
                  {content.login}
                  </button>
                </div>
                <div className="or_txt">
                  <p>
                    <span className="or_line">{content.or}</span>
                  </p>
                </div>
                <div className="already_account">
                  <p>
                  {/* <p>Register new account</p> */}
                    <span>
                      <NavLink to="/forget_password" className="login_link">
                      {content.forgot_password}
                      </NavLink>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
