import React, { useContext } from 'react'
import { images } from '../../actions/customFn'
import { TranslationContext } from '../../context';

const DownloadApp = () => {
    const { content } = useContext(TranslationContext);
    return (
        <section className="download_app">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="mobile_img">
                            <img src={images['mobile-app2.png']} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
                        <div className="download_appContain">
                            <h2>{content.download} <br /><span>{content.locker} </span>{content.APP}</h2>
                            <p>{content.you_can_search_for_service}</p>
                            <div className="download_appIcon">
                                <img src={images["app-store.png"]} alt="" />
                                <img src={images['play-store.png']} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp