import React, { useContext, useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/fontawesome-free-solid";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { notifySuccess } from "../../actions/customFn";
import { useDispatch } from "react-redux";
import { setLogout } from "../../actions/loginAction";
import { useNavigate } from "react-router-dom";
import { TranslationContext } from "../../context";
const SideProfile = (props) => {
  const { content } = useContext(TranslationContext);
  const customerprofile = useSelector((state) => state.customerprofile);
  const [profile, setProfile] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (customerprofile.length > 0) {
      setProfile(customerprofile[0]);
    }
  }, [profile, customerprofile]);

  const loggedOut = () => {
    localStorage.removeItem("lcrpassword");
    localStorage.removeItem("lcruserId");
    dispatch(setLogout());
    notifySuccess("Logout Successfully...");
    navigate("/login");
  };

  return (
    <div className="left_profileContainer">
      <div className="white_box">
        <div className="profile_contain">
          <div className="profile_title">
            <h5>{content.my_profile}</h5>
          </div>
          <div className="profile_icon">
            <img src={profile.image} alt="" />
          </div>
          <div className="profile_name">
            <h6>
              {profile.first_name} {profile.last_name}{" "}
            </h6>
            <a href="mailto:williamw.tanaka@gmail.com">{profile.email}</a>
          </div>
          <div className="edit_profile">
            <NavLink to="/edit-profile" className="profile_edit">
              {content.edit_profile}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="white_box">
        <div className="notification_title">
          <NavLink to="/my-profile">
            <h6>{content.my_orders}</h6>
          </NavLink>
        </div>
      </div>
      <div className="white_box">
        <div className="notification_title">
          <NavLink to="/change_password">
            <h6>{content.change_password}</h6>
          </NavLink>
        </div>
      </div>
      <div className="white_box">
        <div className="notification_title">
          <NavLink to="/notification">
            <h6>
              {content.notifications}{" "}
              {/* <span className="bell_icon">
                <FontAwesomeIcon icon={faBell} />
              </span> */}
            </h6>
          </NavLink>
        </div>
      </div>
      <div className="logout_contain">
        <button onClick={() => loggedOut()} className="logout_btn">
          {content.logout}
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22 12L17 16V13H8V11H17V8L22 12Z" fill="" />
              <path
                d="M10.999 2.999C12.1815 2.99572 13.3529 3.22711 14.4453 3.67976C15.5377 4.13241 16.5294 4.79733 17.363 5.636L15.949 7.05C14.627 5.728 12.869 4.999 10.999 4.999C9.12904 4.999 7.37104 5.728 6.04904 7.05C4.72704 8.372 3.99804 10.13 3.99804 12C3.99804 13.87 4.72704 15.628 6.04904 16.95C7.37104 18.272 9.12904 19.001 10.999 19.001C12.869 19.001 14.627 18.272 15.949 16.95L17.363 18.364C15.664 20.064 13.404 21.001 10.999 21.001C8.59404 21.001 6.33404 20.064 4.63504 18.364C2.93504 16.665 1.99804 14.405 1.99804 12C1.99804 9.595 2.93504 7.335 4.63504 5.636C5.46864 4.79733 6.46037 4.13241 7.55278 3.67976C8.64519 3.22711 9.81656 2.99572 10.999 2.999Z"
                fill=""
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SideProfile;
