import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { React, useState, useEffect, Suspense } from "react";
import "./assets/vendor/css/bootstrap.min.css";
import "./assets/vendor/css/style.css";
import "./assets/vendor/css/rtlstyle.css";
import "./assets/vendor/css/app.css";
import "./assets/vendor/css/responsive.css";
import "./assets/vendor/dist/assets/owl.carousel.css";
import "./assets/vendor/dist/assets/owl.theme.default.min.css";
import { GetRoutes } from "./actions/customFn";
import { Route, Routes } from "react-router-dom";
import RouteArr from "./hooks/GetRoutes";
import i18n from "./i18n";
import LocaleContext from "./components/LocaleContext";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "react-bootstrap";
import Loading from "./components/Language/Loading";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetCustomerDetail } from "./actions/myprofile";
import { setLogin } from "./actions/loginAction";
import { ToastContainer } from "react-toastify";
import { Footer, Header, HomeFooter } from "./components";
import { asyncGetSetting, asyncWebSetings } from "./actions/settingAction";
import NotFound from "./pages/NotFound";
import Script from "react-load-script";
import { TranslationContext } from "./context";
import axios from "./axios";
import {
  asyncSetLanguage,
  getTranlation,
  setLanguage,
} from "./actions/languageAction";
import UnderContruction from "./pages/UnderContruction";
function App() {
  const navigate = useNavigate();
  const [locale, setLocale] = useState("en");
  const [content, setContent] = useState({});
  const [settings, setSettings] = useState({});
  const [webStatus, setWebStatus] = useState("Inactive");
  const languageReducer = useSelector((state) => state.languageReducer);
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));
  const location = useLocation();
  const navigation = useNavigate();
  const getLanguage = localStorage.getItem("get_language");

  const routePath = useLocation();
  const dispatch = useDispatch();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  const isLoggedIn = useSelector((state) => state.login);
  useEffect(() => {
    dispatch(asyncWebSetings(setSettings));
    if (webStatus === "Active" && typeof webStatus !== "undefined") {
      navigation("/");
    }
  }, [dispatch, navigation, webStatus]);

  useEffect(() => {
    if (localStorage.getItem("lcrpassword")) {
      const userDetail = {
        password: localStorage.getItem("lcrpassword"),
        language_code: localStorage.getItem("get_language"),
        device_id: "56565",
        customer_id: localStorage.getItem("lcruserId"),
      };
      dispatch(setLogin());
      dispatch(asyncGetCustomerDetail(userDetail, navigation));
    } else {
      RouteArr.map((route) => {
        if (route.private === true) {
          if (route.path === location.pathname) {
            navigation("/login");
          }
        }
      });
    }
  }, [dispatch, isLoggedIn, location.pathname, navigation]);

  useEffect(() => {
    if (getLanguage === null) {
      if (typeof settings.default_language !== "undefined") {
        dispatch(asyncSetLanguage(settings.default_language));
      }
    } else {
      dispatch(asyncSetLanguage(getLanguage));
    }
    dispatch(getTranlation(setContent));
    dispatch(asyncGetSetting());
  }, [dispatch, getLanguage, settings.default_language]);

  useEffect(() => {
    setWebStatus(settings.under_construction);
    document.getElementById("favicon").href =
      typeof settings.favicon_icon !== "undefined" ? settings.favicon_icon : "";
    document.getElementById("title").text =
      typeof settings.meta_title !== "undefined" ? settings.meta_title : "";
  }, [
    settings.default_language,
    settings.favicon_icon,
    settings.meta_title,
    settings.under_construction,
  ]);

  const getlocation = location.pathname.split("/");
  useEffect(() => {
    if (getlocation) {
      if (
        getlocation[1] !== "login" &&
        getlocation[1] !== "forget_password" &&
        getlocation[1] !== "reset_password" &&
        getlocation[1] !== "reset_password" &&
        getlocation[1] !== "signup" &&
        getlocation[1] !== "booking"
      ) {
        localStorage.removeItem("specialquantity");
        localStorage.removeItem("quantity");
        localStorage.removeItem("dropDate");
        localStorage.removeItem("pickUpDate");
      } else {
        if (getlocation[1] === "booking") {
          if (getlocation[2] !== localStorage.getItem("id")) {
            localStorage.removeItem("specialquantity");
            localStorage.removeItem("quantity");
            localStorage.removeItem("dropDate");
            localStorage.removeItem("pickUpDate");
            console.log("sdsd");
          }
        }
      }
    }
  }, [getlocation, location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={getLanguage === "ar" ? "rtl_body" : "ltr_body"}>
        <LocaleContext.Provider value={{ locale, setLocale }}>
          <Suspense>
            <Helmet
              htmlAttributes={{
                lang: locale,
                dir: getLanguage === "ar" ? "rtl" : "ltr",
              }}
            />
            <ThemeProvider dir={getLanguage === "ar" ? "rtl" : "ltr"}>
              <TranslationContext.Provider value={{ content, settings }}>
                {webStatus !== "Active" && typeof webStatus !== "undefined" ? (
                  <>
                    <div
                      className={
                        location.pathname === "/" ? "header_new_section" : ""
                      }
                    >
                      <Header />
                    </div>
                    <Routes>
                      {GetRoutes(RouteArr)}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    <HomeFooter />
                  </>
                ) : typeof webStatus !== "undefined" ? (
                  <Routes>
                    <Route exact path="/" element={<UnderContruction />} />
                  </Routes>
                ) : (
                  ""
                )}
              </TranslationContext.Provider>
            </ThemeProvider>
          </Suspense>
        </LocaleContext.Provider>
      </div>
    </>
  );
}

export default App;
