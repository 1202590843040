import { combineReducers } from "redux";
import bookingTotalReducer from "./bookingTotalReducer";
import countryReducer from "./countryReducer";
import LanguageReducer from "./languageReducer";
import loginReducer from "./loginReducer";
import propfilereducer from "./profilereducer";

export const reducers = combineReducers({
    login: loginReducer,
    customerprofile: propfilereducer,
    getCountry: countryReducer,
    getTotal: bookingTotalReducer,
    languageReducer: LanguageReducer,
})