import React, { useContext, useEffect, useRef, useState } from "react";
import { images } from "../../actions/customFn";
import Autocomplete from "react-google-autocomplete";
import { map_key } from "../../config";
import { useNavigate } from "react-router-dom";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { TranslationContext } from "../../context";

const HomeBanner = () => {
  const { content, settings } = useContext(TranslationContext);
  const [formErrors, setFormErrors] = useState({});
  const [address, setAddress] = useState("");
  const [map_key, setMapKey] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLangitude] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setMapKey(settings.google_map_key);
    if (typeof settings.google_map_key != "undefined") {
      Geocode.setApiKey(settings.google_map_key);
    }
  }, [settings.google_map_key]);

  const errors = {};
  // console.log("content",content)
  const validation = () => {
    if (address === "") {
      errors.address = content.enter_address;
    }
    if (latitude === "") {
      errors.address = content.enter_valid_address;
    }
    if (longitude === "") {
      errors.address = content.enter_valid_address;
    }
    setFormErrors(errors);
  };

  const inputRef = useRef();
  const handleOnChange = (place) => {
    // const [place] = inputRef.current.getPlaces();
    setAddress(place.formatted_address);
    setLatitude(place.geometry.location.lat());
    setLangitude(place.geometry.location.lng());
  };

  const onClickFilter = () => {
    validation();
    if (Object.keys(errors).length === 0) {
      var _address = "";
      if (address != "") {
        var _address = address.replaceAll(" ", "_");
        var _address = _address.replaceAll("/", "_");
      }
      navigate(`/visit-lockr/${latitude}/${longitude}/${_address}`);
    }
  };
  const getCurrentLocation = (shopLat, shopLng, shopAddress) => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            var address = response.results[0].formatted_address;
            setDefaultAddress(address);
            setLatitude(position.coords.latitude);
            setLangitude(position.coords.longitude);
            setAddress(address);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      function (errorObj) {
        // alert(errorObj.code + ": " + errorObj.message);
      }
    );
  };

  return (
    <section className="homebanner_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"></div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 ">
            <div className="right_bannerContain">
              <div className="banner_txt">
                <h2 className="">{content.Lock_your_bag_unlock_your_life}</h2>
                <p className="">{content.we_conected_you}</p>
              </div>
              <div className="search_location">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="findLocation">
                      {/* <LoadScript
                        id="script-loader"
                        googleMapsApiKey={map_key}
                        libraries={["places"]}
                      >
                        <StandaloneSearchBox
                          className={
                            `form-control input_field   ` +
                            (formErrors.address ? "is-invalid" : "")
                          }
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handleOnChange}
                        >
                          <input
                            className={
                              `form-control input_field   ` +
                              (formErrors.address ? "is-invalid" : "")
                            }
                            defaultValue={defaultAddress}
                            type="text"
                            placeholder="Enter Location"
                          />
                        </StandaloneSearchBox>
                      </LoadScript> */}
                      {map_key !== "" ? (
                        <Autocomplete
                          libraries={["places"]}
                          apiKey={map_key !== "" ? map_key : ""}
                          className={`form-control input-text search_input`}
                          defaultValue={defaultAddress}
                          onPlaceSelected={(place) => {
                            handleOnChange(place);
                          }}
                          options={{
                            // fields: [
                            //   "address_components",
                            //   "geometry",
                            //   "icon",
                            //   "name",
                            // ],
                            types: ["establishment"],
                          }}
                        />
                      ) : (
                        ""
                      )}

                      {formErrors.address && (
                        <div className="text-danger">{formErrors.address}</div>
                      )}
                      <div className="find_btn">
                        <a
                          href={void 0}
                          onClick={() => {
                            getCurrentLocation();
                          }}
                          className="icon_loc "
                        >
                          {" "}
                          <span>
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 561 561"
                              fill="#FFC900"
                            >
                              <path d="M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102 C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55 C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55 C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102 S459,181.05,459,280.5S379.95,459,280.5,459z"></path>
                            </svg>
                          </span>
                        </a>
                        <button
                          type="button"
                          onClick={() => {
                            onClickFilter();
                          }}
                          className="findBtn"
                        >
                          {content.find}
                        </button>
                      </div>
                      <div className="find_btn_desktop">
                        <a
                          href={void 0}
                          onClick={() => {
                            getCurrentLocation();
                          }}
                          className="icon_loc_desktop"
                        >
                          {" "}
                          <span>
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 561 561"
                              fill="#FFC900"
                            >
                              <path d="M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102 C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55 C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55 C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102 S459,181.05,459,280.5S379.95,459,280.5,459z"></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="try_img">
                <img src={images["try_img.png"]} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
