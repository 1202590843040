import axios from "../../axios";
import React, { useContext, useEffect, useState } from "react";
import { images, notifyDanger } from "../../actions/customFn";
import { setLogout } from "../../actions/loginAction";
import { useDispatch } from "react-redux";
import Loading from "../Language/Loading";
import Pagination from "react-js-pagination";
import { TranslationContext } from "../../context";
import { NavLink } from "react-router-dom";

const NotificationInfo = () => {
  const { content } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications_list, setNotifications] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const notifications = async () => {
      setIsLoading(true);
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/notification";
      axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            setNotifications(data.data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response.status == "401") {
            notifyDanger("Somthing went wrong...");
            dispatch(setLogout());
          } else {
            alert(`${err.message}-${err.response.status}`);
          }
        });
    };
    notifications();
  }, []);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * ordersPerPage;
  const indexOfFirstPost = indexOfLastPost - ordersPerPage;
  const currentOrders = notifications_list.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  //Pagination

  return (
    <div className="right_editProfile">
      <div className="white_box edit_whiteBox">
        <div className="editProfile_title notification_txt">
          <h2>{content.notifications}</h2>
        </div>
      </div>
      <div className="white_box">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="notification_list">
              <ul>
                {notifications_list.length > 0 ? (
                  currentOrders.map((val, index) => {
                    return (
                      <>
                        {val.order_id !== "" ? (
                          <NavLink to={`/order-detail/${val.order_id}`}>
                            <li key={index}>
                              <span className="list_icon">
                                <img src={val.icon} alt="" />
                              </span>
                              {val.message}
                            </li>
                            <div className="border_bottom">
                              <hr />
                            </div>
                          </NavLink>
                        ) : (
                          <>
                            <li key={index}>
                              <span className="list_icon">
                                <img src={val.icon} alt="" />
                              </span>
                              {val.message}
                            </li>
                            <div className="border_bottom">
                              <hr />
                            </div>
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <li>
                      <h3>{content.no_record_found}</h3>
                    </li>
                    <div className="border_bottom">
                      <hr />
                    </div>
                  </>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
      {notifications_list.length > 5 ? (
        <div className="pagination_list text-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={ordersPerPage}
            totalItemsCount={notifications_list.length}
            pageRangeDisplayed={notifications_list.length / ordersPerPage}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
            ulClass="pagination justify-content-center"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NotificationInfo;
