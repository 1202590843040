import axios from '../axios';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { asyncGetCustomerDetail } from './myprofile';
import { notifyDanger, notifySuccess } from './customFn';
import { useNavigate } from 'react-router-dom';

export const setLogin = () => {
    return {
        type: 'SET_LOGIN',
        payload: true
    }
}

export const setLogout = () => {
    return {
        type: 'SET_LOGOUT',
        payload: false
    }
}

const CheckLogin = () => {
    const isLoggedIn = useSelector((state) => state.login);
    return isLoggedIn
}


export const asyncLogin = (formData, resetForm, setloginStatus) => {
    return (dispatch) => {
        const url = "/login";
        axios
            .post(url, formData)
            .then((response) => {
                const data = response.data;
                if (data.status) {
                    resetForm();
                    localStorage.setItem("lcrpassword", data.password);
                    localStorage.setItem("lcruserId", data.customer_id);
                    dispatch(setLogin());
                    setloginStatus(true);
                    notifySuccess(data.message);
                } else {
                    notifyDanger(data.message);
                }

            })
            .catch((err) => {
                alert(`${err.message}-${err.statusCode}`);
            });
    };
};



export const asyncGetLogout =(navigation)=>{
    return(dispatch)=>{
        localStorage.removeItem("lcruserId");
        localStorage.removeItem("lcrpassword");
        dispatch(setLogout());
        navigation('/login');
    }
}


