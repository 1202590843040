import axios from "../axios";
import { notifyDanger, notifySuccess } from "./customFn";
import { asyncGetLogout, setLogout } from "./loginAction";

export const setProfile = (data) => {
  return {
    type: "SET_PROFILE",
    payload: data,
  };
};

export const asyncGetCustomerDetail = (data,navigation) => {
  return (dispatch) => {
    const data = {
      password: localStorage.getItem("lcrpassword"),
      language_code: localStorage.getItem("get_language"),
      device_id: "56565",
      customer_id: localStorage.getItem("lcruserId"),
    };
    const url = "/profile";
    axios.post(url, data).then((response) => {
      const data = response.data;
      if (data.status) {
        dispatch(setProfile([data.data]));
      } else {
        if(data.message === "401"){
          dispatch(asyncGetLogout(navigation));
        }else{
          notifyDanger("Inavlid Crediential");
        }
      }
    }).catch((err) => {
      if (err.response.status === "401") {
        dispatch(asyncGetLogout(navigation));
        notifyDanger("Somthing went wrong...");
      } else {
        alert(`${err.message}-${err.response.status}`);
      }
    });
  };
};

export const asyncUpdateProfile = (_data,navigation) => {
  return (dispatch) => {
    const url = "/profileupdate";
    const options = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .post(url, _data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          dispatch(asyncGetCustomerDetail());
          notifySuccess(data.message);
        } else {
          if(data.message === '401'){
            navigation('/login')
          }else{
            notifyDanger(data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === "401") {
          dispatch(asyncGetLogout(navigation));
          notifyDanger("Somthing went wrong...");
        } else {
          alert(`${err.message}-${err.response.status}`);
        }
      });
  };
};


export const asyncChangePassword= (_data,resetForm,navigation) => {
  return (dispatch) => {
    const url = "/changepassword";
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    axios
      .post(url, _data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          notifySuccess(data.message);
          localStorage.setItem("_password", data.password);
          resetForm()
        } else {
          if(data.message === "401"){
            dispatch(asyncGetLogout(navigation));
          }else{
            notifyDanger(data.message);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === "401") {
          dispatch(asyncGetLogout(navigation));
          notifyDanger("Somthing went wrong...");
        } else {
          alert(`${err.message}-${err.response.status}`);
        }
      });
  };
};
