import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  NewHeader,
  SideProfile,
  ProfileForm,
  ChangePassword,
} from "../components";

const EditProfile = () => {
  const location = useLocation();
  return (
    <>
      {/* <NewHeader /> */}
      <section className="account_section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <SideProfile />
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
              {location.pathname === "/change_password" ? (
                <ChangePassword />
              ) : (
                <ProfileForm />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditProfile;
