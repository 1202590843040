import { React, useState, useEffect, useContext } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/fontawesome-free-solid";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import LocaleContext from "../LocaleContext";
import { NavDropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../axios";
import { setLanguage } from "../../actions/languageAction";
import { map_key } from "../../config";
import Geocode from "react-geocode";
import { TranslationContext } from "../../context";
const Header = () => {
  const { pathname } = useLocation();
  const { content, settings } = useContext(TranslationContext);
  Geocode.setApiKey(map_key);
  const dispatch = useDispatch();
  const customerprofile = useSelector((state) => state.customerprofile);
  const isLoggedIn = useSelector((state) => state.login);
  const [profile, setProfile] = useState([]);
  const [languageList, selLanguageList] = useState([]);
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();
  function changeLocale(l) {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (customerprofile.length > 0) {
      setProfile(customerprofile[0]);
    }
  }, [profile, customerprofile]);

  useEffect(() => {
    const languageList = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const data = {
        language_code: "th",
        device_id: "56565",
      };
      const url = "/languages";
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            if (data.data.length > 0) {
              selLanguageList(data.data);
            }
          } else {
            selLanguageList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    languageList();
  }, []);

  const changeLanguage = (code) => {
    dispatch(setLanguage(code));
    localStorage.setItem("get_language", code);
    changeLocale(code);
  };

  const getCurrentLocation = (shopLat, shopLng, shopAddress) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          var address = response.results[0].formatted_address;
          let _address = "";
          if (address != "") {
            _address = address.replaceAll(" ", "_");
            _address = _address.replaceAll("/", "_");
          }

          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          navigate(`/visit-lockr/${latitude}/${longitude}/${_address}`);
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const getLogin = () => {
    navigate("/login", { state: pathname });
  };
  return (
    <section className="header_section account_header">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <NavLink className="navbar-brand logo" to="/">
            <img
              src={settings.header_logo}
              className="img-fluid logo_img"
              alt=""
            />
          </NavLink>
          <button
            className="toggle_menu navbar-toggler"
            onClick={toggleDrawer}
            type="button"
            id="menu_toggle_btn"
          >
            <div className="bars">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </button>
          <div
            className="collapse navbar-collapse header_menu"
            id="header_menu"
          >
            <span className="slide-toggle">
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>

            <div className="right_menu">
              <ul className="navbar-nav ml-auto location_list">
                <li className="nav-item ">
                  <a
                    href={void 0}
                    onClick={() => {
                      getCurrentLocation();
                    }}
                    className="nav-link header_btn help_btn"
                  >
                    {content.map}
                  </a>
                </li>
                {/* <li className="nav-item">
                    <div className="lang_dropdown shop_lang desktop_dropdwn">
                      <div
                        className="language-picker js-language-picker"
                        data-trigger-classname="btn btn--subtle"
                      >
                        <form
                          action=""
                          className="language-picker__form select_none"
                        >
                          <label htmlFor="language-picker-select">
                            Select your language
                          </label>
                          <select
                            defaultValue={"english"}
                            name="language-picker-select"
                            id="language-picker-select"
                          >
                            <option lang="de" value="deutsch"></option>
                            <option lang="en" value="english"></option>
                            <option lang="fr" value="francais"></option>
                            <option lang="it" value="italiano"></option>
                          </select>
                        </form>
                      </div>
                    </div>
                  </li> */}
                <li className="nav-item lang_drop_down">
                  <NavDropdown title={t("Language")} id="basic-nav-dropdown">
                    {languageList.length > 0
                      ? languageList.map((value, index) => {
                          return (
                            <NavDropdown.Item
                              key={index}
                              href="#"
                              onClick={() => changeLanguage(value.code)}
                            >
                              {" "}
                              <span className="icon_lang">
                                {/* <img
                                  src={images["united-kingdom.png"]}
                                  className="img-fluid"
                                  alt=""
                                />{" "} */}
                              </span>{" "}
                              {value.name}
                            </NavDropdown.Item>
                          );
                        })
                      : ""}
                  </NavDropdown>
                </li>
              </ul>
              {isLoggedIn === true ? (
                <>
                  {/* <ul className="navbar-nav ml-auto my_account">
                      <li className="nav-item active">
                        <NavLink
                          to="/my-profile"
                          className="nav-link account_menu"
                        >
                          My Account
                        </NavLink>
                      </li>
                    </ul> */}
                  <div className="notify">
                    <NavLink to="/notification">
                      <span className="bell_icon">
                        <FontAwesomeIcon icon={faBell} />
                      </span>
                    </NavLink>
                  </div>

                  <ul className="navbar-nav ml-auto user_profile">
                    <li className="nav-item dropdown">
                      <NavLink
                        className="nav-link"
                        data-toggle="dropdown"
                        to="/my-profile"
                      >
                        <img src={profile.image} alt="" />
                        <p>
                          {profile.first_name} <span> {profile.last_name}</span>
                        </p>
                        <a
                          href="javascript:void(0);"
                          className="user_email new_user"
                        >
                          {" "}
                          {profile.email}
                        </a>
                      </NavLink>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="navbar-nav ml-auto my_account">
                  <li className="nav-item active">
                    <a
                      href={void 0}
                      onClick={() => {
                        getLogin();
                      }}
                      className="nav-link account_menu"
                    >
                      {content.login}
                    </a>
                  </li>
                  <li className="nav-item active">
                    <NavLink to="/signup" className="nav-link account_menu">
                      {content.signup}
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </div>

          <div className="mobileScreen_menu">
            <div className="lang_dropdown shop_lang mobile_drpdwn">
              <div
                className="language-picker js-language-picker"
                data-trigger-classname="btn btn--subtle"
              >
                <form action="" className="language-picker__form select_none">
                  <label htmlFor="language-picker-select">
                    {content.select_your_language}
                  </label>
                  <NavDropdown title={t("Language")} id="basic-nav-dropdown">
                    {languageList.length > 0
                      ? languageList.map((value, index) => {
                          return (
                            <NavDropdown.Item
                              key={index}
                              href="#"
                              onClick={() => changeLanguage(value.code)}
                            >
                              {" "}
                              <span className="icon_lang">
                                {/* <img
                                  src={images["united-kingdom.png"]}
                                  className="img-fluid"
                                  alt=""
                                />{" "} */}
                              </span>{" "}
                              {value.name}
                            </NavDropdown.Item>
                          );
                        })
                      : ""}
                  </NavDropdown>
                  <select
                    defaultValue={"english"}
                    name="language-picker-select"
                    id="language-picker-select"
                  >
                    <option lang="de" value="deutsch"></option>
                    <option lang="en" value="english"></option>
                    <option lang="fr" value="francais"></option>
                    <option lang="it" value="italiano"></option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </nav>
        <div className="mobile_main_screen">
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="right"
            className="side_bar_main"
          >
            <button
              type="button"
              onClick={toggleDrawer}
              className="close"
              data-dismiss="modal"
            >
              &times;
            </button>
            <div className="new_nav_list">
              <ul className="navbar-nav ml-auto location_list">
                <li className="nav-item ">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      getCurrentLocation();
                    }}
                    className="nav-link header_btn help_btn"
                  >
                    {content.map}
                  </a>
                </li>
                <li className="nav-item">
                  <NavDropdown title={t("Language")} id="basic-nav-dropdown">
                    {languageList.length > 0
                      ? languageList.map((value, index) => {
                          return (
                            <NavDropdown.Item
                              key={index}
                              href="#"
                              onClick={() => changeLanguage(value.code)}
                            >
                              {" "}
                              <span className="icon_lang">
                                {/* <img
                                  src={images["united-kingdom.png"]}
                                  className="img-fluid"
                                  alt=""
                                />{" "} */}
                              </span>{" "}
                              {value.name}
                            </NavDropdown.Item>
                          );
                        })
                      : ""}
                  </NavDropdown>
                </li>
                <li className="nav-item">
                  <div className="lang_dropdown shop_lang desktop_dropdwn">
                    <div
                      className="language-picker js-language-picker"
                      data-trigger-classname="btn btn--subtle"
                    >
                      <form
                        action=""
                        className="language-picker__form select_none"
                      >
                        <label htmlFor="language-picker-select">
                          {content.select_your_language}
                        </label>
                        <select
                          defaultValue={"english"}
                          name="language-picker-select"
                          id="language-picker-select"
                        >
                          <option lang="de" value="deutsch"></option>
                          <option lang="en" value="english"></option>
                          <option lang="fr" value="francais"></option>
                          <option lang="it" value="italiano"></option>
                        </select>
                      </form>
                    </div>
                  </div>
                </li>

                {isLoggedIn === true ? (
                  <li className="nav-item active">
                    <NavLink to="/my-profile" className="nav-link account_menu">
                      {content.my_account}
                    </NavLink>
                  </li>
                ) : (
                  <ul className="navbar-nav  my_account">
                    <li className="nav-item active">
                      <a
                        href={void 0}
                        onClick={() => {
                          getLogin();
                        }}
                        className="nav-link account_menu"
                      >
                        {content.login}
                      </a>
                    </li>
                    <li className="nav-item active">
                      <NavLink to="/signup" className="nav-link account_menu">
                        {content.signup}
                      </NavLink>
                    </li>
                  </ul>
                )}
              </ul>

              {/* {isLoggedIn === true ? (
                <ul className="navbar-nav ml-auto my_account">
                  <li className="nav-item active">
                    <NavLink to="/my-profile" className="nav-link account_menu">
                      My Account
                    </NavLink>
                  </li>
                </ul>
              ) : (
                ""
              )} */}
            </div>
          </Drawer>
        </div>
      </div>
    </section>
  );
};

export default Header;
