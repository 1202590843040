import React from "react";
import { NewHeader, SideProfile, NotificationInfo } from "../components";
const Notification = () => {
  return (
    <section className="account_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <SideProfile />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <NotificationInfo />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Notification;
