import axios from "../axios";
import React from "react";
import { NavLink } from "react-router-dom";
import { Header, BookingForm, Footer } from "../components";
import { useEffect } from "react";
import { useState } from "react";
import { notifyDanger } from "../actions/customFn";
import Loading from "../components/Language/Loading";
const TermAndConditions = () => {
  const [term_condition, setTermCondition] = useState({
    title: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getTermCondition = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/termsandcondition";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          console.log("data", data)
          if (data.status === true) {
            setTermCondition({
              title: data.data.title,
              content: data.data.description,
            });
            setIsLoading(false);
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    // if (languageReducer.length !== 0) {
    getTermCondition();
    // }
  }, []);
  return (
    <section className="store_bag">
      <div className="container">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="store_title">
              <h2>{term_condition.title}</h2>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div
                  className="booking_detail"
                  dangerouslySetInnerHTML={{ __html: term_condition.content }}
                ></div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default TermAndConditions;
