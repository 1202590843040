import axios from "../axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { asyncGetCustomerDetail } from "./myprofile";
import { notifyDanger, notifySuccess } from "./customFn";
import { useNavigate } from "react-router-dom";

export const setLanguage = (data) => {
  return {
    type: "SET_LANGUAGE",
    payload: data,
  };
};

export const getTranlation = (setContent) => {
  return (dispatch) => {
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    const data = {
      language_code: localStorage.getItem("get_language"),
      device_id: "1a579c2db7ea",
    };
    const url = "/translation";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status === true) {
          setContent(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const asyncSetLanguage = (data) => {
  return (dispatch) => {
    dispatch(setLanguage(data));
    localStorage.setItem("get_language", data);
  };
};
