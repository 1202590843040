import React, { useContext } from 'react'
import { images } from '../../actions/customFn'
import { TranslationContext } from '../../context';

const BecomePartner = () => {
    const { content } = useContext(TranslationContext);
    return (
        <section className="becomePartner_section">
            <div className="container">
                <div className="row align-items-end ptrn_main_inner">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="becomePartner_img">
                            <img src={images['become-partner.png']} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <div className="becomePartner_title">
                            <h2>{content.become_partner}</h2>
                            <p><span className='new_text'>{content.with} </span>  L<span><img src={images['lock_img.png']} alt="" /></span>CKR</p>
                            <div className="becomePartner_btn">
                                <a href="#" className="partnerBtn">{content.become_partner}<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row sp_bottm">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="partner_card">
                            <h2>{content.want_to_gain}</h2>
                            <p>{content.locker_provides_free}</p>
                            <a href="#" className="seeMore">{content.see_more}<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="partner_card">
                            <h2>{content.want_to_gain}</h2>
                            <p>{content.locker_provides_free}</p>
                            <a href="#" className="seeMore">{content.see_more}<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="partner_card">
                            <h2>{content.want_to_gain}</h2>
                            <p>{content.locker_provides_free}</p>
                            <a href="#" className="seeMore">{content.see_more}<span><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BecomePartner
