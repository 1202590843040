import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Header, MapLocation } from "../components";
const VisitLockr = () => {
  const { lat , lng, address } = useParams();
  return <MapLocation latitude={lat} longitude={lng} address={address} />;
};

export default VisitLockr;
