import React from 'react';
import axios from "../axios";
import { notifyDanger } from '../actions/customFn';
import { Header, BookingForm, Footer } from '../components';
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../components/Language/Loading';
const PrivacyPolicy = () => {
  const [privacypolicy, setPrivacyPollicy] = useState({
    title: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getprivacypolicy = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/privacypolicy";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setPrivacyPollicy({
              title: data.data.title,
              content: data.data.description,
            });
            setIsLoading(false);
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    // if (languageReducer.length !== 0) {
    getprivacypolicy();
    // }
  }, []);
  return (
    <section className="store_bag">
      <div className="container">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="store_title">
              <h2>{privacypolicy.title}</h2>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div
                  className="booking_detail"
                  dangerouslySetInnerHTML={{ __html: privacypolicy.content }}
                ></div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};



export default PrivacyPolicy
