import React from 'react';
import { Header, BookingForm, Footer } from '../components';
const Booking = () => {
    return (
        <>
            <BookingForm />
        </>
    )
}

export default Booking
