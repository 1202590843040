import { React, useState, useEffect, useContext } from "react";
import { Header, Footer } from "../components";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin } from "../actions/loginAction";
import { setLogin } from "../actions/loginAction";
import axios from "../axios";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import { TranslationContext } from "../context";
const ForgotPassword = () => {
  const { content } = useContext(TranslationContext);
  const [formErrors, setFormErrors] = useState({});
  const [email, setEmail] = useState('');
  const dispatch          = useDispatch();
  const errors            = {};
  const navigation        = useNavigate();


  const resetForm = () => {
    setEmail('');
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    if (validator.isEmail(email) || email.length != 0) {
      console.log("email----",email)
      const formData = {
        email:email,
        language_code: localStorage.getItem("get_language"),
        device_id:'225sd5sd5',
      }
      dispatch(asyncForgotPassword(formData,resetForm));
    }else{
      errors.email = content.enter_valid_email_id;
      setFormErrors(errors);
    }
  };


  const asyncForgotPassword = (formData, resetForm) => {
    return (dispatch) => {
      const url = "/forgotpasword";
      axios
        .post(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            resetForm();
            notifySuccess(data.message);
            navigation("/reset_password");
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((err) => {
          alert(`${err.message}-${err.statusCode}`);
        });
    };
  };

  return (
    <section className="sign_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="sign_title">
              <h2>{content.forgot_password}</h2>
            </div>
          </div>
        </div>
        <form action="" className="my_profile_form">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.email}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.email ? "is-invalid" : "")
                  }
                  id="email"
                  placeholder={content.enter_email}
                  name="email"
                  onChange={(e)=>{setEmail(e.target.value)}}                 
                />
                {formErrors.email && (
                  <div className="invalid-feedback">{formErrors.email}</div>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12"></div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="">
                <div className="sign_form_btn">
                  <button type="submit" onClick={handleOnClick} className="form_register_btn">
                    {content.submit}
                  </button>
                </div>
                <div className="or_txt">
                  <p>
                    <span className="or_line">{content.or}</span>
                  </p>
                </div>
                <div className="already_account">
                  <p>
                    {content.already_have_an_account}
                    <span>
                      <NavLink to="/login" className="login_link">
                        {content.signin}
                      </NavLink>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgotPassword;
