import React, { useContext } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faFacebook,
} from "@fortawesome/fontawesome-free-solid";
import { NavLink } from "react-router-dom";
import { TranslationContext } from "../../context";

const HomeFooter = () => {
  const { content ,settings } = useContext(TranslationContext);
  return (
    <div className="home_new_footer">
      <div className="container">
        <div className="new_ftr_inner">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3 ">
              <div className="footer_logo">
                <img src={settings.footer_logo} alt="" />
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
              <div className="footer_content">
                <p>{settings.footer_text}</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 footer_all_page mt-3 mb-3">
              <ul>
                <li>
                  <NavLink to="/privacy_policy">
                    {" "}
                    {content.privacy_policy}{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/terms_and_Conditions">
                    {" "}
                    {content.terms_and_conditions}{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq"> {content.faq}</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
              <ul className="footer-icon-list mb-4">
                <li className="footer-icon-list-item">
                  <a href={`tel:${settings.footer_phone_number}`}>
                    <span className="footer-icon-list-icon">
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    <span className="footer-icon-list-text">{settings.footer_phone_number}</span>
                  </a>
                </li>
                <li className="footer-icon-list-item">
                  <a href={`mailto:${settings.footer_email}`}>
                    <span className="footer-icon-list-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span className="footer-icon-list-text">
                      {settings.footer_email}
                    </span>
                  </a>
                </li>
              </ul>

              <div className="footer_social_icon">
                <ul>
                  <li className="footer-sicon-list-item">
                    <a href={settings.facebook}>

                    <span className="footer-icon-list-sicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_40"
                          data-name="Group 40"
                          transform="translate(97 -263)"
                        >
                          <g
                            id="Rectangle_103"
                            data-name="Rectangle 103"
                            transform="translate(-97 263)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                            opacity="0"
                          >
                            <rect width="24" height="24" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              fill="none"
                            />
                          </g>
                          <path
                            id="facebook"
                            d="M2.3,18V9.554H0V6.513H2.3v-2.6C2.3,1.875,3.655,0,6.782,0a19.526,19.526,0,0,1,2.2.118l-.074,2.84s-.955-.009-2-.009c-1.128,0-1.309.505-1.309,1.344v2.22H9L8.852,9.554H5.605V18H2.3"
                            transform="translate(-89 266)"
                            fill="#ff7638"
                          />
                        </g>
                      </svg>
                    </span>
                    </a>
                  </li>
                  <li className="footer-sicon-list-item">
                     <a href={settings.twiter}>
                    <span className="footer-icon-list-sicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_39"
                          data-name="Group 39"
                          transform="translate(97 -311)"
                        >
                          <g
                            id="Rectangle_104"
                            data-name="Rectangle 104"
                            transform="translate(-97 311)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                            opacity="0"
                          >
                            <rect width="24" height="24" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              fill="none"
                            />
                          </g>
                          <path
                            id="twitter-bird"
                            d="M18,1.658a7.311,7.311,0,0,1-1.845,1.826q.011.2.011.458a9.72,9.72,0,0,1-.432,2.839A9.934,9.934,0,0,1,14.415,9.5,10.9,10.9,0,0,1,12.3,11.8a9.54,9.54,0,0,1-2.949,1.6,11.2,11.2,0,0,1-3.691.6A10.613,10.613,0,0,1,0,12.411a8.336,8.336,0,0,0,.881.049,7.447,7.447,0,0,0,4.587-1.515,3.686,3.686,0,0,1-2.144-.706,3.483,3.483,0,0,1-1.3-1.75,3.851,3.851,0,0,0,1.667-.059,3.631,3.631,0,0,1-2.12-1.218A3.334,3.334,0,0,1,.725,4.965V4.922A3.769,3.769,0,0,0,2.4,5.364,3.573,3.573,0,0,1,1.2,4.1,3.335,3.335,0,0,1,.755,2.423a3.361,3.361,0,0,1,.5-1.777A10.44,10.44,0,0,0,4.621,3.256,10.62,10.62,0,0,0,8.865,4.341a3.317,3.317,0,0,1-.1-.8,3.335,3.335,0,0,1,1.082-2.5A3.637,3.637,0,0,1,12.46,0a3.63,3.63,0,0,1,2.7,1.116A7.556,7.556,0,0,0,17.5.257a3.449,3.449,0,0,1-1.622,1.957A7.638,7.638,0,0,0,18,1.658Z"
                            transform="translate(-94 316)"
                            fill="#ff7638"
                          />
                        </g>
                      </svg>
                    </span>
                    </a>
                  </li>
                  <li className="footer-sicon-list-item">
                  <a href={settings.pintrest}>
                    <span className="footer-icon-list-sicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_37"
                          data-name="Group 37"
                          transform="translate(97 -407)"
                        >
                          <g
                            id="Rectangle_106"
                            data-name="Rectangle 106"
                            transform="translate(-97 407)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                            opacity="0"
                          >
                            <rect width="24" height="24" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              fill="none"
                            />
                          </g>
                          <path
                            id="pinterest"
                            d="M9,0a8.952,8.952,0,0,1,9,9,9.1,9.1,0,0,1-9,9,9.4,9.4,0,0,1-2.547-.369q.253-.408.505-.9a10.779,10.779,0,0,0,.7-2.236q.117-.476.331-1.254a1.958,1.958,0,0,0,.845.778,3.878,3.878,0,0,0,3.333-.136A5.494,5.494,0,0,0,14.7,10.633c.8-3.055-.233-5.992-3-7.173-3.34-1.007-7.069.25-8.368,3.236-.567,2-.533,4.559,1.438,5.394a.354.354,0,0,0,.282,0A2.266,2.266,0,0,0,5.4,10.875a.733.733,0,0,0-.136-.243c-1.09-1.476-.425-3.672.593-4.772,1.732-1.488,4.428-1.729,6.016-.311,1.409,1.644,1.089,4.2.185,5.832a2.55,2.55,0,0,1-2.07,1.409,1.4,1.4,0,0,1-1.419-1.73,21.6,21.6,0,0,0,.8-3.188c-.046-.827-.46-1.351-1.225-1.361a2.162,2.162,0,0,0-1.73,2.332,3.6,3.6,0,0,0,.292,1.419q-.388,1.574-.6,2.536a28.128,28.128,0,0,0-.709,3.431q-.029.545-.01,1.011A9.084,9.084,0,0,1,0,9,9.049,9.049,0,0,1,9,0Z"
                            transform="translate(-94 410)"
                            fill="#ff7638"
                          />
                        </g>
                      </svg>
                    </span></a>
                  </li>
                  <li className="footer-sicon-list-item">
                  <a href={settings.instagram}>
                    <span className="footer-icon-list-sicon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g
                          id="Group_38"
                          data-name="Group 38"
                          transform="translate(97 -359)"
                        >
                          <g
                            id="Rectangle_105"
                            data-name="Rectangle 105"
                            transform="translate(-97 359)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                            opacity="0"
                          >
                            <rect width="24" height="24" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              fill="none"
                            />
                          </g>
                          <g
                            id="bxl-instagram-alt"
                            transform="translate(-94 362)"
                          >
                            <path
                              id="Path_7"
                              data-name="Path 7"
                              d="M22.438,9.783a6.506,6.506,0,0,0-.418-2.211,4.6,4.6,0,0,0-2.632-2.628A6.613,6.613,0,0,0,17.2,4.525c-.962-.043-1.266-.055-3.708-.055s-2.754,0-3.708.055A6.613,6.613,0,0,0,7.6,4.944,4.6,4.6,0,0,0,4.969,7.572a6.53,6.53,0,0,0-.418,2.18c-.043.961-.056,1.265-.056,3.7s0,2.748.056,3.7a6.568,6.568,0,0,0,.418,2.182A4.6,4.6,0,0,0,7.6,21.965a6.591,6.591,0,0,0,2.184.449c.962.043,1.267.056,3.708.056s2.754,0,3.708-.056A6.6,6.6,0,0,0,19.388,22a4.61,4.61,0,0,0,2.632-2.628,6.545,6.545,0,0,0,.418-2.182c.043-.96.056-1.264.056-3.7s0-2.746-.058-3.7Zm-8.949,8.28a4.613,4.613,0,1,1,4.621-4.613,4.616,4.616,0,0,1-4.621,4.613Zm4.805-8.322A1.075,1.075,0,1,1,19.37,8.666,1.075,1.075,0,0,1,18.294,9.742Z"
                              transform="translate(-4.495 -4.47)"
                              fill="#ff7638"
                            />
                            <path
                              id="Path_8"
                              data-name="Path 8"
                              d="M19.49,16.46a3,3,0,1,1-3-3,3,3,0,0,1,3,3Z"
                              transform="translate(-7.494 -7.48)"
                              fill="#ff7638"
                            />
                          </g>
                        </g>
                      </svg>
                    </span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy_right">
        <p>{settings.copyrights}</p>
      </div>
    </div>
  );
};

export default HomeFooter;
