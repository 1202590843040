import React, { useContext, useEffect, useState } from "react";
import { images, notifyDanger } from "../../actions/customFn";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/fontawesome-free-solid";
import "animate.css";
import axios from "../../axios";
import Loading from "../Language/Loading";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Accordion } from "react-bootstrap";
import { TranslationContext } from "../../context";
import BookingModal from "../Modal/BookingModal";
const TabAll = (props) => {
  // Payment Modal
  const [useEffectrender, setuseEffectrender] = useState(false);
  const [BookModal, setBookingModal] = useState(false);
  const [userbookingDetail, setUserBookingDetail] = useState({
    drop_date: "",
    pick_date: "",
    quantity: "",
    special_quantity: "",
    name: "",
    shop_id: "",
    insurance_check: "",
    order_id: "",
    currency_code: "",
    currency_symbol: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const [bookingTotalamount, setBookingTotalAmount] = useState(0);
  const closeBookingModal = () => {
    setBookingModal(false);
  };

  const { content } = useContext(TranslationContext);
  const { setAllCount } = props;
  const [orderList, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeImage, setActiveImage] = useState("deactive");
  const [show, setShow] = useState(false);
  const image = images["bag.png"];

  useEffect(() => {
    const ordersList = async () => {
      setIsLoading(true);
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
        status: "Checked",
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/orders";
      axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            setOrders(data.data.orders);
            setIsLoading(false);
            setAllCount(data.data.order_count);
            // console.log("data.data.orders", data.data.orders.length)
          }
        })
        .catch((err) => {
          if (err.response.status === "401") {
            notifyDanger("Somthing went wrong...");
          } else {
            alert(`${err.message}-${err.response.status}`);
          }
        });
    };
    ordersList();
  }, []);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * ordersPerPage;
  const indexOfFirstPost = indexOfLastPost - ordersPerPage;
  const currentOrders = orderList.slice(indexOfFirstPost, indexOfLastPost);
  //Pagination

  //Remaining Amount
  const handleRemainingAmount = (value) => {
    const data = {
      customer_id     : localStorage.getItem("lcruserId"),
      password        : localStorage.getItem("lcrpassword"),
      device_id       : "dfdfd",
      language_code   : localStorage.getItem("get_language"),
      shop_id         : value.shop_id,
      quantity        : value.quantity,
      special_quantity: value.special_quantity,
      drop_date       : value.drop_date,
      pickup_date     : value.pickup_date,
      insurance_id    : value.insurance_id,
      currency        : value.currency_code,
      order_id        : value.id,
    };
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    const url = "/validdateorder";
    axios
      .post(url, data, options)
      .then((response) => {
        if (response.data.status === true) {
          setUserBookingDetail({
            drop_date       : value.drop_date,
            pick_date       : value.pickup_date,
            quantity        : value.quantity,
            special_quantity: value.special_quantity,
            name            : value.shop_name,
            shop_id         : value.shop_id,
            insurance_check : value.insurance_id,
            order_id        : value.id,
            currency_code   : value.currency_code,
            currency_symbol : value.currency_symbol,
          });
          setBookingTotalAmount(value.remaining_total);

          setBookingModal(true);
        } else {
          notifyDanger(response.data.message);
        }
      })
      .catch((err) => {});
  };

  return (
    <section className="account_section">
      <div className="tab_all">
        <div className="booking_itemList">
          {isLoading === true ? (
            <Loading />
          ) : orderList.length > 0 ? (
            currentOrders.map(function (value, index) {
              return (
                <div className="white_box" key={index}>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <NavLink to={`/order-detail/${value.id}`}>
                        <div className="prod_item">
                          <img src={value.image} alt="" />
                        </div>
                      </NavLink>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center order2">
                      <div className="prod_itemDetail">
                        <div className="prod_title">
                          <NavLink to={`/order-detail/${value.id}`}>
                            <h5>{value.shop_name}</h5>
                          </NavLink>
                          <p> </p>
                        </div>
                        <div className="drop_prodAddress">
                          <h6> </h6>
                          <p>{value.shop_address}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="total_bags">
                        <h6>{content.total_bagss}</h6>
                        <p>{value.quantity}</p>
                      </div>
                      {value.remaining_total !== null ||
                      value.remaining_total > 0 ? (
                        <div className="Viewdetail_btn ">
                          <button
                            className=" remaining"
                            onClick={() => {
                              handleRemainingAmount(value);
                            }}
                          >
                            {content.amount_to_pay}
                            <p>
                              {" "}
                              {value.currency_symbol} {value.remaining_total}
                            </p>
                          </button>
                        </div>
                      ) : (
                        <>
                          {show === false || show !== index ? (
                            <div className="Viewdetail_btn">
                              <button
                                onClick={() => setShow(index)}
                                className="view_more"
                              >
                                {content.view_details}
                                <span className="more_angle rotate">
                                  <FontAwesomeIcon
                                    icon={faAngleDown}
                                  ></FontAwesomeIcon>
                                </span>
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>

                    {show === index ? (
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 details_open "
                        defaultActiveKey="0"
                      >
                        <div
                          className="view_prodDetail viewMore animate__animated animate__fadeIn"
                          id="show_more"
                        >
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                              <div className="prod_entry">
                                <div className="grey_box check_txt check_in">
                                  <h6>
                                    {content.check_in}
                                    <span className="luggage_icon">
                                      <img
                                        src={images["check-in.png"]}
                                        alt=""
                                      />
                                    </span>
                                  </h6>
                                  <p>{value.drop_date}</p>
                                </div>
                                <div className="grey_box check_txt check_out">
                                  <h6>
                                    {content.check_out}
                                    <span className="luggage_icon">
                                      <img
                                        src={images["check-out.png"]}
                                        alt=""
                                      />
                                    </span>
                                  </h6>
                                  <p>{value.pickup_date}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                              <div className="qr-grey grey_box">
                                <div className="qr_codeImg">
                                  <img
                                    src={value.qrcode_image}
                                    style={{ width: "128px" }}
                                    alt=""
                                  />
                                </div>
                                <div className="qr_codeContain">
                                  <div className="qr_value recoveryCode">
                                    <h6>{content.customer_name}</h6>
                                    <p>
                                      {value.first_name} {value.last_name}
                                    </p>
                                  </div>
                                  <div className="qr_value orderId">
                                    <h6>{content.order_id} </h6>
                                    <p>{value.order_id}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btn_close">
                          <button onClick={() => setShow(false)}>
                            {" "}
                            {content.close_details}
                            <FontAwesomeIcon
                              icon={faAngleUp}
                            ></FontAwesomeIcon>{" "}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="white_box">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-6 text-center">
                  <h3>{content.no_record_found}</h3>
                </div>
              </div>
            </div>
          )}
        </div>
        {orderList.length > 10 ? (
          <div className="pagination_list text-center">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={ordersPerPage}
              totalItemsCount={orderList.length}
              pageRangeDisplayed={orderList.length / ordersPerPage}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
              ulClass="pagination justify-content-center"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <BookingModal
        BookingModal={BookModal}
        detail={userbookingDetail}
        BookingcloseModal={closeBookingModal}
        setTotalAmount={setTotalAmount}
        bookingTotalamount={bookingTotalamount}
        setuseEffectrender={setuseEffectrender}
      />
    </section>
  );
};

export default TabAll;
