import React, { useContext } from 'react'
import { images } from '../../actions/customFn'
import { TranslationContext } from '../../context';

const StoreBag = () => {
    
  const { content } = useContext(TranslationContext);
    return (
        <section className="homeStore_bag">
            <div className="container">
                <div className="store_title">
                    <h2>{content.store_steps_in}</h2>
                </div>
                {/* <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="store_step">
                            <div className='step_dis'>
                                <img src={images["store-img1.png"]} alt="" />
                                <div className="step_txt">
                                    <h5>Locate</h5>
                                    <p>Simply book and pay online via LOCKR mobile application</p>
                                </div>
                            </div>

                            <div className="arrow_step">
                                <img src={images["arrow.png"]} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="store_step">
                            <div className='step_dis'>
                                <img src={images['store-img2.png']} alt="" />
                                <div className="step_txt">
                                    <h5>Book</h5>
                                    <p>Proceed with online booking</p>
                                </div>
                            </div>

                            <div className="arrow_step">
                                <img src={images['arrow.png']} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="store_step">
                            <div className='step_dis'>
                                <img src={images['store-img3.png']} alt="" />
                                <div className="step_txt">
                                    <h5>Store</h5>
                                    <p>Store your suitcases</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    </div> */}

                <div className='Bag_box'>

                    <div className="store_step">
                        <div className='step_mar'>
                            <div className='step_dis'>
                                <img src={images["store-img1.png"]} alt="" />
                                <div className="step_txt">
                                    <h5>{content.book_the_service}</h5>
                                    <p>{content.simply_book_and_pay_online}</p>
                                </div>
                            </div>
                        </div>
                        <div className="arrow_step">
                            <img src={images["arrow.png"]} alt="" />
                        </div>
                    </div>


                    <div className="store_step">
                        <div className='step_mar'>
                            <div className='step_dis'>
                                <img src={images['store-img2.png']} alt="" />
                                <div className="step_txt">
                                <h5>{content.book_the_service}</h5>
                                    <p>{content.proceed_with_online_booking}</p>
                                </div>
                            </div>
                        </div>


                        <div className="arrow_step">
                            <img src={images['arrow.png']} alt="" />
                        </div>
                    </div>

                    <div className="store_step">
                        <div className='step_mar'>
                            <div className='step_dis'>
                                <img src={images['store-img3.png']} alt="" />
                                <div className="step_txt">
                                <h5>{content.proceed_with_online_booking}</h5>
                                    <p> {content.store_your_suitcases}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StoreBag