import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import {
  asyncChangePassword,
  asyncUpdateProfile,
} from "../../actions/myprofile";
import { TranslationContext } from "../../context";

const ProfileForm = () => {
  const { content } = useContext(TranslationContext);
  const customerprofile = useSelector((state) => state.customerprofile);
  const getCountry = useSelector((state) => state.getCountry);
  const [profile, setProfile] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formErrors2, setFormErrors2] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const errors = {};
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [customer, setCustomer] = useState({
    profile_image: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    passport_id: "",
    nationality_id: "",
  });



  useEffect(() => {
    setCountryData(getCountry);
    if (customerprofile.length > 0) {
      setProfile(customerprofile[0]);
      setCustomer({
        first_name: profile.first_name,
        last_name: profile.last_name,
        phone_number: profile.phone_number,
        email: profile.email,
        passport_id: profile.passport_id,
        nationality_id: profile.nationality_id,
        profile_image: profile.image,
      });
      setProfileImage(profile.image)
    }
  }, [profile, customerprofile, getCountry]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'profile_image') {
      setCustomer({ ...customer, [name]: e.target.files[0] });
      setProfileImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setCustomer({ ...customer, [name]: value });
    }

  };

  const validation = () => {

    if (customer.first_name.length === 0) {
      errors.first_name = content.enter_first_name;
    }
    if (customer.last_name.length === 0) {
      errors.last_name = content.enter_last_name;
    }
    if (!validator.isNumeric(customer.phone_number)) {
      errors.phone_number = content.phone_must_be_numeric;
    } else if (customer.phone_number.length === 0) {
      errors.phone_number = content.enter_phone_no;
    } else if (customer.phone_number.length < 10) {
      errors.phone_number = content.phone_no_digit_must_be;
    }
    if (customer.passport_id === "") {
      errors.passport_id = content.enter_passport_Id;
    }
    if (customer.nationality_id === "") {
      errors.nationality_id = content.please_Select_country;
    }
    setFormErrors(errors);
  };



  const handleUpdateProfile = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      const formdata = new FormData();
      formdata.append('customer_id', localStorage.getItem("lcruserId"))
      formdata.append('password', localStorage.getItem("lcrpassword"))
      formdata.append('first_name', customer.first_name)
      formdata.append('last_name', customer.last_name)
      formdata.append('phone_number', customer.phone_number)
      formdata.append('passport_id', customer.passport_id)
      formdata.append('image', customer.profile_image)
      formdata.append('nationality_id', customer.nationality_id)
      formdata.append('language_code', localStorage.getItem("get_language"))
      formdata.append('device_id', "15255")

      // const data = {
      //     customer_id: localStorage.getItem("lcruserId"),
      //     password: localStorage.getItem("lcrpassword"),
      //     first_name: customer.first_name,
      //     last_name: customer.last_name,
      //     phone_number: customer.phone_number,
      //     passport_id: customer.passport_id,
      //     nationality_id: customer.nationality_id,
      //     image: customer.profile_image,
      //     language_code: "en",
      //     device_id: "15255",
      //   };
      dispatch(asyncUpdateProfile(formdata, navigation));

    }
  };

  return (
    <section className="account_section editprofile_Section">
      <div className="right_editProfile">
        <div className="white_box edit_whiteBox">
          <div className="editProfile_title">
            <h2>{content.edit_profile}</h2>
          </div>
        </div>
        <div className="white_box">

          <div className="userProfile_form">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="userProfile_input">
                  <div class="profile_icon">
                    <img alt="" src={profileImage} />
                    <div className="my_profile">
                      <input type="file" name="profile_image" id="profile_image" onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.first_name}</p>
                  <input
                    type="text"
                    className={
                      `form-control name_input  ` +
                      (formErrors.first_name ? "is-invalid" : "")
                    }
                    name="first_name"
                    onChange={handleChange}
                    value={customer.first_name}
                    placeholder={content.enter_first_name}
                  />
                  {formErrors.first_name && (
                    <div className="invalid-feedback">
                      {formErrors.first_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.last_name}</p>
                  <input
                    type="text"
                    name="last_name"
                    onChange={handleChange}
                    value={customer.last_name}
                    className={
                      `form-control name_input  ` +
                      (formErrors.last_name ? "is-invalid" : "")
                    }
                    placeholder={content.enter_last_name}
                  />
                  {formErrors.last_name && (
                    <div className="invalid-feedback">
                      {formErrors.last_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.email_address}</p>
                  <input
                    type="email"
                    name="email"
                    value={customer.email}
                    readonly={true}
                    className="form-control name_input"
                    placeholder={content.email_address}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.phone_number}</p>
                  <input
                    type="number"
                    name="phone_number"
                    onChange={handleChange}
                    value={customer.phone_number}
                    className={
                      `form-control name_input  ` +
                      (formErrors.phone_number ? "is-invalid" : "")
                    }
                    placeholder={content.enter_phone_number}
                  />
                  {formErrors.phone_number && (
                    <div className="invalid-feedback">
                      {formErrors.phone_number}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.passport_Id_number}</p>
                  <input
                    type="text"
                    name="passport_id"
                    onChange={handleChange}
                    value={customer.passport_id}
                    className={
                      `form-control name_input  ` +
                      (formErrors.passport_id ? "is-invalid" : "")
                    }
                    placeholder={content.enter_passport_Id_number}
                  />
                  {formErrors.passport_id && (
                    <div className="invalid-feedback">
                      {formErrors.passport_id}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="userProfile_input">
                  <p>{content.country}</p>
                  <select
                    name="nationality_id"
                    id="nationality_id"
                    className={
                      `form-control input_field   ` +
                      (formErrors.nationality_id ? "is-invalid" : "")
                    }
                    value={customer.nationality_id}
                    onChange={handleChange}
                  >
                    <option value="">{content.select_country}</option>
                    {countryData.length > 0 &&
                      countryData.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {formErrors.nationality_id && (
                    <div className="invalid-feedback">
                      {formErrors.nationality_id}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>


          <div className="border_bottom">
            <hr />
          </div>
          <div className="user_profileBtn">
            <div className="password_checkbox">

            </div>
            <div className="save_btn">
              <a
                href="javascript:void(0);"
                onClick={handleUpdateProfile}
                className="saveBtn"
              >
               {content.save_changes}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileForm;
