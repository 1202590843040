
import { Home, MyProfile, EditProfile, Notification, VisitLockr, Booking, YourBooking, Login, Signup, OrderDetail, HomeMain } from '../pages';
import ChangePassword from '../components/EditProfile/ChangePassword';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import PrivacyPolicy from '../pages/PrivacyPoliciy';
import TermAndConditions from '../pages/TermAndCondition';
import Faq from '../pages/Faq';
import ReviewList from '../pages/ReviewList';
import UnderContruction from '../pages/UnderContruction';
const RouteArr = [
    {
        name: "Home",
        path: "/",
        key: "home",
        route: "/",
        private: false,
        page: <HomeMain />,
    },
    {
        name: "HomeMain",
        path: "/home-main",
        key: "/home-main",
        route: "/home-main",
        page: <HomeMain />,
    },
    {
        name: "MyProfile",
        path: "/my-profile",
        key: "/my-profile",
        route: "/my-profile",
        private: true,
        page: <MyProfile />,
    },
    {
        name: "EditProfile",
        path: "/edit-profile",
        key: "/edit-profile",
        route: "/edit-profile",
        private: true,
        page: <EditProfile />,
    },
    {
        name: "Notification",
        path: "/notification",
        key: "/notification",
        route: "/notification",
        private: true,
        page: <Notification />,
    },
    {
        name: "VisitLockr",
        path: "/visit-lockr/:lat/:lng/:address",
        key: "/visit-lockr",
        route: "/visit-lockr/:lat/:lng/:address",
        private: false,
        page: <VisitLockr />,
    },
    {
        name: "Booking",
        path: "booking/:id",
        key: "/booking",
        route: "booking/:id",
        private: true,
        page: <Booking />,
    },
    {
        name: "OrderDetail",
        path: "/order-detail/:id",
        key: "/order-detail",
        route: "/order-detail/:id",
        private: false,
        page: <OrderDetail />,
    },
    {
        name: "Login",
        path: "/login",
        key: "/login",
        route: "/login",
        private: false,
        page: <Login />,
    },
    {
        name: "Signup",
        path: "/signup",
        key: "/signup",
        route: "/signup",
        private: false,
        page: <Signup />,
    },
    {
        name: "Forgot Password",
        path: "/forget_password",
        key: "/forget_password",
        route: "/forget_password",
        private: false,
        page: <ForgotPassword />,
    },
    {
        name: "ResetPassword",
        path: "/reset_password",
        key: "/reset_password",
        route: "/reset_password",
        private: false,
        page: <ResetPassword />,
    },
    {
        name: "ChangePasswrod",
        path: "/change_password",
        key: "/change_password",
        route: "/change_password",
        private: true,
        page: <EditProfile />,
    },
    {
        name: "Privacy policy",
        path: "/privacy_policy",
        key: "/privacy_policy",
        route: "/privacy_policy",
        private: false,
        page: <PrivacyPolicy />,
    },
    {
        name: "Terms and Conditions",
        path: "/terms_and_conditions",
        key: "/terms_and_Conditions",
        route: "/terms_and_Conditions",
        private: false,
        page: <TermAndConditions />,
    },
    {
        name: "Faq",
        path: "/faq",
        key: "/faq",
        route: "/faq",
        private: false,
        page: <Faq />,
    },
    {
        name: "review_list",
        path: "/review_list/:id",
        key: "/review_list",
        route: "/review_list/:id",
        private: false,
        page: <ReviewList />,
    },
]



export default RouteArr;