import React, { useContext, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabAll from "./TabAll";
import axios from "../../axios";
import Loading from "../Language/Loading";
import { notifyDanger } from "../../actions/customFn";
import UpComingTab from "./UpComingTab";
import CompletedTab from "./CompletedTab";
import { TranslationContext } from "../../context";
const BookingInfo = () => {
  const { content }                         = useContext(TranslationContext);
  const [isLoading, setIsLoading]           = useState(true);
  const [checkIn_orders_count, setAllCount] = useState(0);
  const [upcoming_count, setUpcomingCount]  = useState(0);
  const [expiredCount, setExpiredCount]     = useState(0);
  return (
    <div className="booking_info_all">
      <div className="right_userBooking">
        <div className="userbooking_tab mt-2">
          <Tabs
            defaultActiveKey={"Upcoming Booking"}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="Upcoming Booking"
              title={`${content.upcoming_booking} ( ${upcoming_count} )`}
            >
              <UpComingTab setUpcomingCount={setUpcomingCount} />
            </Tab>
            <Tab
              eventKey="Checked-in"
              title={`${content.checked_in} ( ${checkIn_orders_count} )`}
            >
              <TabAll setAllCount={setAllCount} />
            </Tab>
            <Tab eventKey="Expired" title={`${content.expired} ( ${expiredCount} )`}>
              <CompletedTab setCompletedCount={setExpiredCount} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;
