import { React, useState, useEffect, useContext } from "react";
import { Header, Footer } from "../components";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin } from "../actions/loginAction";
import { setLogin } from "../actions/loginAction";
import axios from "../axios";
import { notifyDanger, notifySuccess } from "../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import { TranslationContext } from "../context";
const ResetPassword = () => {
  const { content } = useContext(TranslationContext);
  const [formErrors, setFormErrors] = useState({});
  const [register, setRegister] = useState({
    email:'',
    otp:'',
    new_password:'',
    confirm_password:''
  });
  const dispatch          = useDispatch();
  const errors            = {};
  const navigation        = useNavigate();


  const resetForm = () => {
    setRegister({
        otp:'',
        email:'',
        new_password:'',
        confirm_password:''
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegister({ ...register, [name]: value });
  };

 

  const validation = () => {
    if (!validator.isEmail(register.email) || register.email.length === 0) {
        errors.email = content.enter_valid_email_id;
      }
    if (register.otp.length === 0) {
      errors.otp = content.enter_otp;
    }   
    if (register.new_password.length === 0) {
      errors.password = content.enter_new_Password;
    } else if (register.new_password.length < 6) {
      errors.password = content.new_password_6_chr;
    }
    if (register.confirm_password.length === 0) {
      errors.confirm_password = content.enter_confirm_password;
    }
    if (register.new_password !== register.confirm_password) {
      errors.confirm_password = content.confirm_password_does_not_match;
    }
    setFormErrors(errors);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
        let formData = {}
        formData = register;
        formData.language_code= localStorage.getItem("get_language");
        formData.device_id = 'sdsdklskd';
        dispatch(asyncRestPaswrodPassword(formData,resetForm))
    }
  };




  const asyncRestPaswrodPassword = (formData, resetForm) => {
    return (dispatch) => {
      const url = "/resetpasword";
      axios
        .post(url, formData)
        .then((response) => {
          const { data } = response;
          if (data.status) {
            resetForm();
            notifySuccess(data.message);
            navigation("/login");
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((err) => {
          alert(`${err.message}-${err.statusCode}`);
        });
    };
  };

  return (
    <section className="sign_section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="sign_title">
              <h2>{content.reset_password}</h2>
            </div>
          </div>
        </div>
        <form action="" className="my_profile_form my_reset_password">
          <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.email}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field   ` +
                    (formErrors.email ? "is-invalid" : "")
                  }
                  id="email"
                  placeholder={content.enter_email}
                  name="email"
                  value={register.email}
                  onChange={handleChange}                 
                />
                {formErrors.email && (
                  <div className="invalid-feedback">{formErrors.email}</div>
                )}
              </div>
            </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.otp}</p>
                <input
                  type="text"
                  className={
                    `form-control input_field ` +
                    (formErrors.otp ? " is-invalid" : "")
                  }
                  placeholder="Enter Otp"
                  id="password-input"
                  name="otp"
                  value={register.otp}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {formErrors.otp && (
                  <div className="invalid-feedback">{formErrors.otp}</div>
                )}
              </div>
            </div>
            
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.new_password}</p>
                <input
                  type="password"
                  className={
                    `form-control input_field ` +
                    (formErrors.new_password ? " is-invalid" : "")
                  }
                  placeholder={content.enter_new_Password}
                  id="password-input"
                  name="new_password"
                  value={register.new_password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {formErrors.new_password && (
                  <div className="invalid-feedback">{formErrors.new_password}</div>
                )}
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="contact_input mt-3">
                <p>{content.confirm_password}</p>
                <input
                  type="password"
                  className={
                    `form-control input_field ` +
                    (formErrors.confirm_password ? " is-invalid" : "")
                  }
                  placeholder={content.enter_confirm_password}
                  id="password-input"
                  name="confirm_password"
                  value={register.confirm_password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {formErrors.confirm_password && (
                  <div className="invalid-feedback">{formErrors.confirm_password}</div>
                )}
              </div>
            </div>
           
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="">
                <div className="sign_form_btn">
                  <button type="submit" onClick={handleOnClick} className="form_register_btn">
                    {content.submit}
                  </button>
                </div>
                <div className="or_txt">
                  <p>
                    <span className="or_line">{content.or}</span>
                  </p>
                </div>
                <div className="already_account">
                  <p>
                   {content.already_have_an_account}
                    <span>
                      <NavLink to="/login" className="login_link">
                        {content.signin}
                      </NavLink>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
