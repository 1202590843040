import React, { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  faStar,
  faExclamationCircle,
  faCalendar,
  faClock,
  faBriefcase,
  faPhoneSquare,
  faPhone,
} from "@fortawesome/fontawesome-free-solid";
import {
  NavLink,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "flatpickr/dist/themes/confetti.css";
import Flatpickr from "react-flatpickr";
import BookingModal from "../Modal/BookingModal";
import axios from "../../axios";
import Loading from "../Language/Loading";
import { useDispatch } from "react-redux";
import { asyncGetTotal } from "../../actions/bookingTotal";
import moment from "moment";
import { currency_code, currency_sign, map_key } from "../../config";
import { parse } from "@fortawesome/fontawesome-svg-core";
import Marker from "../VisitLockr/Marker";
import { faMailchimp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/fontawesome-free-regular";
import ReactLoading from "react-loading";
import ReactStars from "react-rating-stars-component";
import NewBookingModal from "../Modal/NewBookingModal";
import { TranslationContext } from "../../context";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const BookingForm = () => {
  const MySwal = withReactContent(Swal);
  const { content, settings } = useContext(TranslationContext);
  const { id } = useParams();
  const [date, setDate] = useState(Date());
  const [BookModal, setBookingModal] = useState(false);
  const [more, setMore] = useState(1); // Name it however you wish
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  const [map_key, setMapKey] = useState("");
  const [currency_code, setCurrencyCode] = useState("");
  const [shopDetail, setShopDetail] = useState("");
  const [bookingTotalamount, setBookingTotalAmount] = useState(0);
  const [quantity, setQuantity] = useState(
    localStorage.getItem("quantity") != null
      ? parseInt(localStorage.getItem("quantity"))
      : 1
  );
  const [specialquantity, setSpecialQuantity] = useState(
    localStorage.getItem("specialquantity") != null
      ? parseInt(localStorage.getItem("specialquantity"))
      : 0
  );
  const [formErrors, setFormErrors] = useState({});

  const [dropOff_date, setdropOff_date] = useState(
    localStorage.getItem("dropDate") != null &&
      typeof localStorage.getItem("dropDate") !== "undefined"
      ? JSON.parse(localStorage.getItem("dropDate"))
      : ""
  );
  const [dropOff_time, setdropOff_time] = useState("");
  const [pickUp_date, setpickUp_date] = useState(
    localStorage.getItem("pickUpDate") != null &&
      typeof localStorage.getItem("pickUpDate") !== "undefined"
      ? JSON.parse(localStorage.getItem("pickUpDate"))
      : ""
  );
  const [pickUp_time, setpickUp_time] = useState("");
  const [bookingDetail, setBookingDetail] = useState([]);
  const [guranteecheck, setGuranteecheck] = useState("");
  const [guranteecheckID, setGuranteecheckID] = useState("");
  const [insurance_list, setInsurance] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [useEffectrender, setuseEffectrender] = useState(false);
  const [onChangeTotal, setOnChangeTotal] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [totalLoader, setTotalLoader] = useState(false);
  const [promoCodeCheck, setPromocodecheck] = useState(false);
  const [promocode_id, setPromocodeId] = useState("");
  const [_error, setErrors] = useState({});
  const [appliedStatus, setPromoAppliedStatus] = useState(false);
  const [defaultProps, setDeafaultprop] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  });
  const [userbookingDetail, setUserBookingDetail] = useState({
    drop_date: "",
    pick_date: "",
    quantity: "",
    special_quantity: "",
    name: "",
  });
  const errors = {};
  const dispatch = useDispatch();

  const showBookingModal = () => {
    setBookingModal(true);
  };
  const closeBookingModal = () => {
    setBookingModal(false);
  };
  const [add_more_count, setAddMoreCount] = useState(0);

  const ratingStar = {
    size: 30,
    count: 5,
    isHalf: false,
    color: "black",
    activeColor: "#fbda5c",
    edit: false,
  };

  const navigation = useNavigate();
  const handleRoomCount = (name, status) => {
    let newQty = 0;
    let newSpecialQty = 0;
    if (status === "increment") {
      if (name === "quantity") {
        newQty = quantity + 1;
        setQuantity(quantity + 1);
        localStorage.setItem("quantity", parseInt(quantity) + 1);
      } else {
        newSpecialQty = specialquantity + 1;
        setSpecialQuantity(specialquantity + 1);
        localStorage.setItem("specialquantity", newSpecialQty);
      }
    } else {
      if (name === "quantity") {
        if (quantity > 0) {
          setQuantity(quantity - 1);
          localStorage.setItem("quantity", parseInt(quantity) - 1);
        }
      } else {
        if (specialquantity > 0) {
          setSpecialQuantity(specialquantity - 1);
          localStorage.setItem(
            "specialquantity",
            parseInt(specialquantity) - 1
          );
        }
      }
    }
  };

  const handleNumberSum = (_count) => {
    let stock = 3;
    let count = _count + 1;
    if (count <= stock) {
      setMore(count);
      setAddMoreCount(count);
    }
  };

  const validation = () => {
    if (dropOff_date === "") {
      errors.dropOff_date = content.enter_drop_off_date;
    }
    if (pickUp_date === "") {
      errors.pickUp_date = content.enter_pick_up_date;
    }
    if (Date.parse(pickUp_date) === Date.parse(dropOff_date)) {
      errors.pickUp_date = content.drop_off_pick_up_are_same;
    }
    // if (Date.parse(pickUp_date) > Date.parse(dropOff_date)) {
    //   errors.pickUp_date = content.drop_off_pick_up_are_same;
    // }
    if (quantity === 0 && specialquantity === 0) {
      errors.quantity = content.enter_quantity_atleast_1;
    }
    setFormErrors(errors);
  };

  useEffect(() => {
    setMapKey(settings.google_map_key);
    setCurrencyCode(settings.default_currency);
  }, [settings.default_currency, settings.google_map_key]);
  //Api Start
  useEffect(() => {
    setIsLoading(true);
    const loadShopDetail = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/shopdetail";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
        shop_id: id,
        customer_id: localStorage.getItem("lcruserId"),
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setShopDetail(data.data);
            setDeafaultprop({
              center: {
                lat: parseFloat(data.data.address_latitude),
                lng: parseFloat(data.data.address_longitude),
              },
              zoom: 11,
            });
            localStorage.setItem("id", id);
            setIsLoading(false);
          } else {
            notifyDanger(data.message);
            navigation("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadShopDetail();
  }, [id]);

  //Insurance APi
  useEffect(() => {
    setIsLoading(true);
    const loadInsurance = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/insurance";
      const data = {
        language_code: "en",
        device_id: "1a579c2db7ea",
        shop_id: id,
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setInsurance(data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadInsurance();
  }, [id]);

  //----submit GetTotal Start
  const handleGetTotal = () => {
    // e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      var dropDate = moment(dropOff_date).format("yyyy-MM-DD h:mm A");
      var pickDate = moment(pickUp_date).format("yyyy-MM-DD h:mm A");
      // if (localStorage.getItem("lcruserId")) {
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: "en",
        shop_id: id,
        quantity: quantity,
        special_quantity: specialquantity,
        drop_date: dropDate,
        pickup_date: pickDate,
        insurance_id: guranteecheck,
        currency: currency_code,
      };
      const setData = {
        dropDate: dropDate,
        pickDate: pickDate,
        quantity: quantity,
        specialquantity: specialquantity,
        name: shopDetail.name,
        shop_id: shopDetail.id,
        guranteecheck: guranteecheck,
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/validdateorder";
      axios
        .post(url, data, options)
        .then((response) => {
          if (response.data.status === true) {
            dispatch(
              asyncGetTotal(
                data,
                setData,
                setUserBookingDetail,
                setBookingModal,
                setTotalAmount,
                setTotalLoader,
                setBookingTotalAmount
              )
            );
          } else {
            notifyDanger(response.data.message);
          }
        })
        .catch((err) => {});
    }
  };
  //----submit Form End

  //Api End

  const handlePromocode = (status) => {
    if (status === "cancle_promo") {
      setPromocodecheck(false);
      setPromocode("");
      setPromocodeId("");
      setTimeout(() => {
        setPromoAppliedStatus(false);
      }, 2000);
    } else {
      if (promoCodeCheck === true) {
        setPromocodecheck(false);
      } else {
        setPromocodecheck(true);
        setPromocode("");
      }
    }
  };

  const Promovalidation = () => {
    if (promocode === "") {
      errors.promocode = content.enter_password;
    }
    if (dropOff_date === "") {
      errors.promocode = content.enter_drop_off_date;
    }
    if (pickUp_date === "") {
      errors.promocode = content.enter_pick_up_date;
    }
    if (Date.parse(pickUp_date) === Date.parse(dropOff_date)) {
      errors.promocode = content.drop_off_pick_up_are_same;
    }
    setErrors(errors);
  };

  //Check Promocode Start
  const handePromocode = (e) => {
    e.preventDefault();
    Promovalidation();
    if (Object.keys(errors).length === 0) {
      var dropDate = "";
      var pickDate = "";
      if (dropOff_date != "") {
        dropDate = moment(dropOff_date).format("yyyy-MM-DD h:mm A");
      }
      if (pickUp_date != "") {
        pickDate = moment(pickUp_date).format("yyyy-MM-DD h:mm A");
      }
      const data = {
        device_id: "dfdfd",
        language_code: localStorage.getItem("get_language"),
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        promotion_code: promocode,
        shop_id: id,
      };
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/applypromotioncode";
      axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            var promocode_id = data.promotion_id;
            setPromocodeId(promocode_id);
            notifySuccess(data.message);
            setPromocodecheck(false);
            setPromoAppliedStatus(true);
            const setData = "";
          } else {
            notifyDanger(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //Check Promocode End

  useEffect(() => {
    var dropDate = "";
    var pickDate = "";
    // validation();
    // if (Object.keys(errors).length === 0) {
    if (dropOff_date != "" && pickUp_date != "") {
      if (dropOff_date != "") {
        dropDate = moment(dropOff_date).format("yyyy-MM-DD h:mm A");
      }
      if (pickUp_date != "") {
        pickDate = moment(pickUp_date).format("yyyy-MM-DD h:mm A");
      }
      const data = {
        customer_id: localStorage.getItem("lcruserId"),
        password: localStorage.getItem("lcrpassword"),
        device_id: "dfdfd",
        language_code: "en",
        shop_id: id,
        quantity: quantity,
        special_quantity: specialquantity,
        drop_date: dropDate,
        pickup_date: pickDate,
        insurance_id: guranteecheck,
        currency: currency_code,
        promotioncode_id: promocode_id,
      };
      const setData = "";
      setTotalLoader(true);
      dispatch(
        asyncGetTotal(
          data,
          setData,
          "",
          "",
          setOnChangeTotal,
          setTotalLoader,
          setBookingTotalAmount
        )
      );
    }
    // }
  }, [
    bookingTotalamount,
    dispatch,
    dropOff_date,
    guranteecheck,
    id,
    pickUp_date,
    promocode_id,
    quantity,
    specialquantity,
  ]);

  //Get
  const getDirection = async (shopLat, shopLng, shopAddress, shopName) => {
    await navigator.geolocation.getCurrentPosition(
      function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        var url = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${shopLat},${shopLng}`;
        // setTimeout(() => {
        MySwal.fire({
          title: `Direction to ${shopName}`,
          showCancelButton: true,
          confirmButtonText: "OK",
        }).then((res) => {
          if (res.isConfirmed) {
            var windRefrence = window.open();
            windRefrence.location = url;
          }
        });
      },
      function (error) {
        alert("error", error);
      }
    );
  };

  //checkGurrentyvalue
  const checkGurrentyvalue = (e) => {
    setGuranteecheckID(e.target.checked);
  };

  return (
    <>
      {/* {
      ispayLoading === true ?
        <div className="new_loader">
          <Loading/>quantity
        </div>
      :
       ''
    } */}
      <section className="booking_section">
        <div className="container">
          <div className="breadcrumb_list">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb arr-right">
                <li className="breadcrumb-item ">
                  <NavLink to="/" className="">
                    {content.search}
                  </NavLink>
                </li>
                <li className="breadcrumb-item ">
                  <a href="#0" className="">
                    {content.booking}
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <a href="#0">{content.confirm}</a>
                </li>
              </ol>
            </nav>
          </div>
          {isLoading === true ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="booking_detail">
                  <div className="booking_place">
                    <h3>{shopDetail.name}</h3>
                    <div className="review_rate">
                      <div className="reviewTxt">
                        {/* <p>{shopDetail.latest_review_rating}</p> */}
                        <ul>
                          <li>
                            <ReactStars
                              {...ratingStar}
                              value={shopDetail.review_count}
                            />
                          </li>
                          <li>
                            {/* <span>({shopDetail.review_count} {content.stars})</span> */}
                          </li>
                        </ul>
                      </div>
                      <div className="secure_txt">
                        {/* <p>
                          <span>
                            {" "}
                            <img src={images["secure.png"]} alt="" />
                          </span>
                          109795 B Guarantee{" "}
                          <span className="exclam_icon">
                            {" "}
                            <FontAwesomeIcon icon={faExclamationCircle} />
                          </span>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border_bottom">
                  <hr />
                </div>
                <div className="place_locDetail">
                  <h6>{content.location}</h6>
                  <p>{shopDetail.address}</p>
                  {/* <p className="office_txt">
                    <span>
                      <FontAwesomeIcon icon={faBriefcase} />
                    </span>
                    Office
                  </p> */}
                  <div className="Placeloc_map">
                    <div style={{ height: "50vh", width: "100%" }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: map_key }}
                        center={defaultProps.center}
                        zoom={defaultProps.zoom}
                      >
                        <Marker
                          title={shopDetail.name}
                          lat={shopDetail.address_latitude}
                          lng={shopDetail.address_longitude}
                        />
                      </GoogleMapReact>
                    </div>
                  </div>
                  <div className="get_direction">
                    <button
                      onClick={() => {
                        getDirection(
                          shopDetail.address_latitude,
                          shopDetail.address_longitude,
                          shopDetail.address,
                          shopDetail.name
                        );
                      }}
                      className="direction_btn"
                      data-toggle="modal"
                      data-target="#Booking"
                    >
                      {content.get_direction}
                    </button>
                  </div>
                </div>
                <div className="border_bottom">
                  <hr />
                </div>
                <div className="opening_hours">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="opn_hrs">
                        <span className="clock_icon">
                          <FontAwesomeIcon icon={faClock} />
                        </span>
                        <p className="open_hours">{content.opening_hours} </p>
                        <p className="open_txt ml-1">{shopDetail.open_hours}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="opn_hrs">
                        <span className="clock_icon">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>{" "}
                        <p className="open_hours">{content.phone_number} </p>
                        <p className="open_txt ml-1">
                          {shopDetail.phone_number}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {shopDetail.email !== "" ? (
                        <div className="opn_hrs">
                          <span className="clock_icon">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <p className="open_hours">{content.email}</p>
                          <p className="open_txt ml-1">{shopDetail.email}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-3">
                      {shopDetail.line_id !== "" ? (
                        <div className="opn_hrs">
                          <div className="clock_icon icon_img text-center">
                            <img src={images["line.png"]} />
                          </div>
                          <p className="open_hours">{content.line_Id}</p>
                          <p className="open_txt ml-1">{shopDetail.line_id}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="border_bottom">
                  <hr />
                </div>
                {shopDetail.latest_review_rating !== 0 &&
                shopDetail.latest_review !== "" ? (
                  <div className="review_txt">
                    <p>{content.reviews}</p>
                    <NavLink to={`/review_list/${id}`} className="review_all">
                      {content.show_all_review}
                    </NavLink>
                    <div className=" mb-2">
                      {/* <ul>
                        <li> */}
                      <div className="reviewTxt d-flex justify-content-between mb-3">
                        <div class="profile">
                          <div class="profile-img">
                            <img src={shopDetail.review_customer_image} />
                          </div>
                          <div class="name-user">
                            <strong>{shopDetail.review_customer_name}</strong>
                          </div>
                        </div>
                        <div>
                          <ReactStars
                            {...ratingStar}
                            value={shopDetail.latest_review_rating}
                          />
                          {/* <span>({shopDetail.latest_review_rating} {content.stars})</span> */}
                        </div>
                      </div>
                      {/* </li>
                       
                      </ul> */}
                    </div>
                    <p>{shopDetail.latest_review}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <div className="book_for">
                  <h2>
                    {content.booking_your_items}{" "}
                    {/* <span className="bag_per">
                      {currency_sign} {shopDetail.price} BAG/Hourly
                    </span> */}
                  </h2>
                  <div className="picker_contain">
                    <div className="drop_title">
                      <h5>{content.drop_off}</h5>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="datepicker date input-group date_picker">
                          <div className="input-group-append iconInput">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faCalendar} />
                            </span>
                          </div>
                          <Flatpickr
                            options={{
                              minDate: "today",
                              disable: [
                                {
                                  function(date) {
                                    return date.getDate() < new Date();
                                  },
                                },
                              ],
                            }}
                            data-enable-time
                            value={dropOff_date}
                            name="dropOff_date"
                            onChange={(selected) => {
                              setdropOff_date(selected[0]);
                              localStorage.setItem(
                                "dropDate",
                                JSON.stringify(selected[0])
                              );
                            }}
                          />
                        </div>
                        {formErrors.dropOff_date && (
                          <div className="text-danger">
                            {formErrors.dropOff_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="picker_contain mt-2">
                    <div className="drop_title">
                      <h5>{content.pick_up}</h5>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="datepicker date input-group date_picker">
                          <div className="input-group-append iconInput">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faCalendar} />
                            </span>
                          </div>
                          <Flatpickr
                            options={{
                              minDate: dropOff_date,
                              disable: [
                                {
                                  function(date) {
                                    return date.getDate() < dropOff_date;
                                  },
                                },
                              ],
                            }}
                            data-enable-time
                            value={pickUp_date}
                            onChange={(selected) => {
                              setpickUp_date(selected[0]);
                              localStorage.setItem(
                                "pickUpDate",
                                JSON.stringify(selected[0])
                              );
                            }}
                            name="pickUp_date"
                          />
                        </div>
                        {formErrors.pickUp_date && (
                          <div className="text-danger">
                            {formErrors.pickUp_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="items_selection mt-3">
                    <div className="bag_main_info">
                      <div className="bag_title_uper">
                        <h2>{content.regular_items}</h2>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5">
                          <img src={images["regular_Items.png"]} alt="" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 inner_info_table">
                          <div className="bag_inner_info brd_btm">
                            <h2 className="title_inner">
                              {content.max_weight_and_dimension}
                            </h2>
                            <span className="tittle_btm_text">
                              {" "}
                              {content.no_limitation}
                            </span>
                          </div>
                          <div className="bag_inner_info">
                            <h2 className="title_inner">{content.price}</h2>
                            <span className="tittle_btm_text">
                              {" "}
                              {settings.default_currency_symbol}{" "}
                              {shopDetail.price}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                          <div className="btn_quantity_inner">
                            <div className="qunty_info_one">
                              <p>{quantity}</p>
                            </div>
                            <div className="qunty_info_sen">
                              <button
                                onClick={() =>
                                  handleRoomCount("quantity", "increment")
                                }
                              >
                                {" "}
                                +{" "}
                              </button>
                              <button
                                onClick={() =>
                                  handleRoomCount("quantity", "decrement")
                                }
                              >
                                {" "}
                                -{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bag_main_info">
                      <div className="bag_title_uper">
                        <h2>{content.special_items}</h2>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-5">
                          <img src={images["special_Items.png"]} alt="" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 inner_info_table">
                          <div className="bag_inner_info brd_btm">
                            <h2 className="title_inner">
                              {content.max_weight_and_dimension}
                            </h2>
                            <span className="tittle_btm_text">
                              {" "}
                              {content.no_limitation}{" "}
                            </span>
                          </div>
                          <div className="bag_inner_info ">
                            <h2 className="title_inner">{content.price}</h2>
                            <span className="tittle_btm_text">
                              {" "}
                              {settings.default_currency_symbol}{" "}
                              {shopDetail.special_price}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                          <div className="btn_quantity_inner">
                            <div className="qunty_info_one">
                              <p>{specialquantity}</p>
                            </div>
                            <div className="qunty_info_sen">
                              <button
                                onClick={() =>
                                  handleRoomCount(
                                    "special_quantity",
                                    "increment"
                                  )
                                }
                              >
                                {" "}
                                +{" "}
                              </button>
                              <button
                                onClick={() =>
                                  handleRoomCount(
                                    "special_quantity",
                                    "decrement"
                                  )
                                }
                              >
                                {" "}
                                -{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {formErrors.quantity && (
                        <div className="text-danger">{formErrors.quantity}</div>
                      )}
                    </div>
                  </div>

                  <div className="pay_section ">
                    <div className="pay_section_heading">
                      <h2>{content.insurance}</h2>
                    </div>
                    {insurance_list.length > 0
                      ? insurance_list.map((value, index) => {
                          return (
                            <>
                              <div className="switch_btn mb-1 m-2" key={index}>
                                <input
                                  type="checkbox"
                                  checked={
                                    guranteecheck === value.id
                                      ? guranteecheckID
                                      : false
                                  }
                                  name={`gurantee_check_${value.id}`}
                                  id={`gurantee_check_${value.id}`}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setGuranteecheck(value.id);
                                    } else {
                                      setGuranteecheck("");
                                    }
                                    checkGurrentyvalue(e);
                                  }}
                                  value={value.id}
                                />
                              </div>
                              <span
                                className="insurance_main_title"
                                htmlFor={`gurantee_check_${value.id}`}
                              >
                                {value.title}{" "}
                                <span className="float-right">
                                  {settings.default_currency_symbol}{" "}
                                  {value.price}
                                </span>
                              </span>
                              <p className="ins_main_dis">
                                {value.description}
                              </p>
                            </>
                          );
                        })
                      : ""}
                  </div>
                  <div className="all_card_details mb-4">
                    <div className="card_table_data">
                      {onChangeTotal.length > 0 ? (
                        <>
                          <h3>{content.booking_summary}</h3>
                        </>
                      ) : (
                        ""
                      )}
                      <table>
                        {totalLoader === true ? (
                          <div className="total_loader">
                            <ReactLoading
                              type="spinningBubbles"
                              color="#ffa101"
                            />
                          </div>
                        ) : (
                          <>
                            {onChangeTotal.length > 0 ? (
                              <>
                                <tr className="summary_tr">
                                  <td>
                                    <span>{content.luggage_storage}</span>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr className="summary_tr">
                                  <td>{content.storage_at}</td>
                                  <td>{shopDetail.name}</td>
                                </tr>
                                <tr className="summary_tr">
                                  <td>{content.drop_off_time}</td>
                                  <td>
                                    {dropOff_date != ""
                                      ? moment(dropOff_date).format(
                                          "yyyy-MM-DD hh:mm"
                                        )
                                      : ""}
                                  </td>
                                </tr>
                                <tr className="summary_tr">
                                  <td>{content.pick_up_time}</td>
                                  <td>
                                    {pickUp_date != ""
                                      ? moment(pickUp_date).format(
                                          "yyyy-MM-DD hh:mm"
                                        )
                                      : ""}
                                  </td>
                                </tr>
                                {onChangeTotal.map((value, index) => {
                                  return (
                                    <tr className="summary_tr" key={index}>
                                      <td>
                                        {" "}
                                        {value.title}
                                        <p className="summary">
                                          {value.description}
                                        </p>
                                      </td>
                                      <td>
                                        {settings.default_currency_symbol}{" "}
                                        {value.price}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}

                            <tr>
                              <td>
                                {appliedStatus === true ? (
                                  <button
                                    className="btn_promo"
                                    onClick={() => {
                                      handlePromocode("cancle_promo");
                                    }}
                                  >
                                    {content.cancle_promo_code}
                                  </button>
                                ) : (
                                  <button
                                    className="btn_promo"
                                    onClick={() => {
                                      handlePromocode("check");
                                    }}
                                  >
                                    {promoCodeCheck === true
                                      ? "Cancle"
                                      : "Enter promo code"}
                                  </button>
                                )}

                                {promoCodeCheck === true ? (
                                  <button
                                    className="btn_promo m-2"
                                    onClick={(e) => {
                                      handePromocode(e);
                                    }}
                                  >
                                    {" "}
                                    {content.add_promo_code}{" "}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                              {promoCodeCheck === true ? (
                                <td>
                                  <div className="promo_input">
                                    <input
                                      type="text"
                                      name="promocode"
                                      value={promocode}
                                      onChange={(e) => {
                                        setPromocode(e.target.value);
                                      }}
                                    />
                                    <span className="promo_svg_icon">
                                      {" "}
                                      <svg
                                        className="MuiSvgIcon-root grey"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                      >
                                        <path d="M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"></path>
                                        <circle
                                          cx="6.5"
                                          cy="6.5"
                                          r="1.5"
                                        ></circle>
                                        <path d="M8.9 12.55c0 .57.23 1.07.6 1.45l3.5 3.5 3.5-3.5c.37-.37.6-.89.6-1.45 0-1.13-.92-2.05-2.05-2.05-.57 0-1.08.23-1.45.6l-.6.6-.6-.59c-.37-.38-.89-.61-1.45-.61-1.13 0-2.05.92-2.05 2.05z"></path>
                                      </svg>
                                    </span>
                                    {_error.promocode && (
                                      <div className="text-danger">
                                        {_error.promocode}
                                      </div>
                                    )}
                                  </div>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="book_now">
                    <button
                      className="book_btn"
                      onClick={handleGetTotal}
                      data-toggle="modal"
                      data-target="#payment"
                    >
                      {content.book_now}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <BookingModal
          BookingModal={BookModal}
          detail={userbookingDetail}
          BookingcloseModal={closeBookingModal}
          setTotalAmount={setTotalAmount}
          bookingTotalamount={bookingTotalamount}
        />
      </section>
    </>
  );
};

export default BookingForm;
