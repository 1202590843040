import React, { useContext } from 'react';
import { images } from '../../actions/customFn';
import { MotionAnimate } from 'react-motion-animate';
import { TranslationContext } from '../../context';

const PricesSection = () => {
    const { content } = useContext(TranslationContext);
    return (
        <section className="prices_section">
            <div className="container">
                <div className="prices_title">
                    <h2>{content.our_price}</h2>
                </div>
                <div className="whitePrice_box">
                    <div className="row ">
                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="luggage_bagImg">
                                <img src={images['luggage-bag.png']} alt="" />
                                <h5>{content.normal_luggage}</h5>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12" >
                            <div className='row row_bg_col'>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt brd_round_top">
                                            <h5>{content.hours}</h5>
                                        </div>
                                        <div className="ourPrice_value  bg_color">
                                            <h4>฿40</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt">
                                            <h5>{content.daily}</h5>
                                            <span>({content._24_hr})</span>
                                        </div>
                                        <div className="ourPrice_value">
                                            <h4>฿150</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt">
                                            <h5>{content.weekly}</h5>
                                            <span>({content._7_days})</span>
                                        </div>
                                        <div className="ourPrice_value  bg_color">
                                            <h4>฿750</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt brd_round_btm">
                                            <h5>{content.monthly}</h5>
                                            <span>({content._30_days})</span>
                                        </div>
                                        <div className="ourPrice_value">
                                            <h4>฿3,000</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row  Special_Items">
                        <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="luggage_bagImg">
                                <img src={images['price_one.png']} alt="" />
                                <h5>{content.special_items}</h5>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9 col-md-9 col-sm-8 col-12 price_desktop" >
                            <div className='row row_bg_col'>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div className="price_table">
                                        {/* <div className="ourPrice_txt">
                                            <h5>Hours</h5>
                                        </div> */}
                                        <div className="ourPrice_value bg_color">
                                            <h4>฿40</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div className="price_table">
                                        {/* <div className="ourPrice_txt">
                                            <h5>Daily</h5>
                                            <span>(24 hr.)</span>
                                        </div> */}
                                        <div className="ourPrice_value">
                                            <h4>฿150</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div className="price_table  bg_color">
                                        {/* <div className="ourPrice_txt">
                                            <h5>Weekly</h5>
                                            <span>(7 days)</span>
                                        </div> */}
                                        <div className="ourPrice_value">
                                            <h4>฿750</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div className="price_table">
                                        {/* <div className="ourPrice_txt">
                                            <h5>Monthly</h5>
                                            <span>(30 days)</span>
                                        </div> */}
                                        <div className="ourPrice_value">
                                            <h4>฿3,000</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12 price_mobile" >
                            <div className='row row_bg_col'>
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt brd_round_top">
                                            <h5>{content.hours}</h5>
                                        </div>
                                        <div className="ourPrice_value  bg_color">
                                            <h4>฿40</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt">
                                            <h5>{content.daily}</h5>
                                            <span>({content._24_hr})</span>
                                        </div>
                                        <div className="ourPrice_value">
                                            <h4>฿150</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt">
                                            <h5>{content.weekly}</h5>
                                            <span>({content._7_days})</span>
                                        </div>
                                        <div className="ourPrice_value  bg_color">
                                            <h4>฿750</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="price_table">
                                        <div className="ourPrice_txt brd_round_btm">
                                            <h5>{content.monthly}</h5>
                                            <span>{content._30_days}</span>
                                        </div>
                                        <div className="ourPrice_value">
                                            <h4>฿3,000</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MotionAnimate animation='scrollPosition' yPos={[10, -650]}>
                    <div className="image_anim">
                        <img src={images['price_bg.png']} alt="" />
                    </div>
                </MotionAnimate>
            </div>

        </section >
    )
}

export default PricesSection
