import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/fontawesome-free-solid";
import axios from "../axios";
import Loading from "../components/Language/Loading";
import { TranslationContext } from "../context";
const Faq = () => {
  const { content } = useContext(TranslationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaq] = useState([]);
  useEffect(() => {
    const getFaqs = async () => {
      const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      };
      const url = "/faq";
      const data = {
        language_code: localStorage.getItem("get_language"),
        device_id: "1a579c2db7ea",
      };
      await axios
        .post(url, data, options)
        .then((response) => {
          const { data } = response;
          if (data.status === true) {
            setFaq(data.data);
            setIsLoading(false);
          } else {
            console.log(data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    // if (languageReducer.length !== 0) {
    getFaqs();
    // }
  }, []);
  return (
    <div className="faq_main_all">
      {console.log("faqs", faqs.length)}
      <div className="container">
        {isLoading === true ? (
          <Loading />
        ) : (
          <>
            <div className="Faq_heading">
              <h2> {content.faq} </h2>
            </div>
            <Accordion defaultActiveKey="_0">
              {faqs.length > 0
                ? faqs.map((value, index) => {
                    return (
                      <Accordion.Item eventKey={`_${index}`} key={index}>
                        <Accordion.Header>
                          {value.title}
                          <span className="faq_angle_down faq_icon_main">
                            <FontAwesomeIcon icon={faAngleDown} />{" "}
                          </span>
                          <span className="faq_angle_up faq_icon_main">
                            {" "}
                            <FontAwesomeIcon icon={faAngleUp} />
                          </span>
                        </Accordion.Header>

                        <Accordion.Body>{value.description}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })
                : ""}
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
};

export default Faq;
