const initialProductState = []

const bookingTotalReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case 'SET_TOTAl':
            return [...action.payload]
        default:
            return state
    }
}

export default bookingTotalReducer