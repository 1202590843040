import axios from '../axios';





export const setCountry = (data) => {
    return {
        type: 'SET_COUNTRY',
        payload: data
    }
}



export const asyncGetSetting = () => {
    return (dispatch) => {
        const url = '/nationalities';
        const data = {
            language_code: localStorage.getItem("get_language"),
            device_id : '32323',
        }
        axios.post(url,data).then(response => {
            dispatch(setCountry(response.data.data.nationalities))
        })
    }
}


export const asyncWebSetings = (setSetings) => {
    return (dispatch) => {
        const url = '/settings';
        const data = {
            device_id : '32323',
            customer_id: localStorage.getItem("lcruserId"),
        }
        axios.post(url,data).then(response => {
            setSetings(response.data.settings)
        })
    }
}


