import React from "react";
import PropTypes from "prop-types"; 
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { images } from "../../actions/customFn";

const Wrapper = styled.img`
  width: 30px;
  height: 30px;
  cursor: ${(props) => (props.onClick ? "pointer" : "pointer")};
  &:hover {
    z-index: 1;
  }
`;
const renderTooltip = (title) => <Tooltip id="button-tooltip">{title}</Tooltip>;

const Marker = ({ title, onClick, uKey, active }) => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip(title)}
  >
    <Wrapper
      alt={title}
      onClick={onClick}
      src={
        active === uKey
          ? images["lock_loc.png"]
          : images["lock_loc.png"]
      }
    />
  </OverlayTrigger>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
