const initialProductState = ''

const LanguageReducer = (state = initialProductState, action) => {

    switch (action.type) {
        case 'SET_LANGUAGE':
            return action.payload
        default:
            return state
    }
}

export default LanguageReducer