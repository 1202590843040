import { Spinner } from "react-bootstrap";
import ReactLoading from "react-loading";

function Loading() {
    return (
        <div className="my_loader" >
        {/* // <Spinner animation="border" role="status"> */}
            {/* <span className="visually-hidden"> */}
                <ReactLoading type="spin"  color="#FBDA5C" />
            {/* </span> */}
        {/* // </Spinner> */}
        </div>
    )
}

export default Loading;